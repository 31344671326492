import "./Button.css";

const Button = (props) => {
  return (
    <a className={"uibtn hoverable " + props.class} href="#0">
      {props.value}
    </a>
  );
};

export default Button;
