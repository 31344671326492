import React from "react";
import appleStore from "../images/appleStore.png";
import playStore from "../images/playstore.png";
import explore from "../images/landingPage.png";
import homepage from "../images/homepage.png";
import menu from "../images/menu.png";
import customize1 from "../images/customize1.png";
import customize2 from "../images/customize.png";
import "./Main.css";

const ArticleSection = () => {
  return (
    <div className="blogPage__article-section">
      <div className="blogPage__article-section-inner">
        <div className="blogPage__article-section-main">
          <p>
            In 2022, we worked with Heavyweight Nigerian producer and music
            executive, Don Jazzy, and in December we deployed & launched Jazzy’s
            Burger App.
          </p>
          <p>
            Our involvement in this project was to design a product from scratch
            that was usable and scalable. To get this done we started off by
            creating a brand identity, though there was an existing logo, we
            replaced it with a better one. Our focus was on usability and we
            used this as a core parameter while designing and developing the
            connected systems. We built a system that was ready to scale from
            the start, and 10m+ requests later, The Jazzy’s Burger App is up and
            running with 0 downtimes.
          </p>
          <p>
            At first glance, it had to be clear that this was a product about
            edible burgers, and the visuals also needed to appeal to the Target
            Audience, i.e., the customer.
          </p>
          <p>
            For the Logo, we felt that using an actual picture of a burger would
            be too on the nose, we needed something more straightforward and
            memorable but still evocative of a delicious burger. We ended up
            using a customized vector with a pleasant Shade of Red for the
            Burger Bun, Titanium White for the sesame seeds, with the company’s
            name stylishly written in black and nested in-between the top and
            lower Burger bun.
          </p>
          <p>
            We stuck with the same color schemes for the remaining visuals in
            the overall brand identity, we shared the results with the client
            and our focus groups and ended up with positive reviews from all
            sides.
          </p>
          <p>
            After the design and branding were done with, we went ahead to
            structure the framework of the application. We prioritized Security
            as the first tenet of the application with the Agile development
            infrastructure. We built the backend on ruby and implemented Cross
            Platform development supporting multiple operating systems like
            Android and IOS. The Frontend for the app was built with flutter and
            the Jazzy burger website is powered by React.js. The Jazzy’s Burger
            App has received over <b>100M+</b> requests, about <b>8000</b>{" "}
            processed orders,
            <b> 20k+</b> Users and has had zero downtimes since Launch.
          </p>
          <p>Now, let’s Explore the Jazzy’s Burger App together;</p>
          <p>
            Go to your Mobile AppStore and search “Jazzy’s Burger”. You will be
            taken to the download page.
          </p>
          <p>This is what it would like on the android playStore:</p>
          <img className="screenshots" src={playStore} alt="/" />
          <p>This is what it would like on the Apple IOS store:</p>
          <img className="screenshots" src={appleStore} alt="/" />
          <p>
            {" "}
            Click on install and wait for it to download and install on your
            device.
          </p>
          <p>
            After installation, open up the app, and you will be greeted with
            the explore page:
          </p>
          <img className="screenshots" src={explore} alt="/" />
          <p>
            Click on the Register text link, input your details and you will be
            sent a secure OTP to complete the signup details. On sign-in, the
            homepage becomes visible and you can begin to explore the different
            categories and menu offerings.
          </p>
          <img className="screenshots" src={homepage} alt="/" />
          <p>
            The Homepage has a beautiful UI which is also very user-friendly and
            easy to navigate. It also has an animated side menu that slides in
            from the left:
          </p>
          <img className="screenshots" src={menu} alt="/" />
          <p>
            {" "}
            After selecting which burgers you want, we added an option for you
            to customize your burger by adding your own personalized fillings
            and removing whichever fillings you don’t want.
          </p>
          <div className="customized-menu-container">
            <img className="screenshots" src={customize1} alt="/" />
            <img className="screenshots" src={customize2} alt="/" />
          </div>
          <p>
            Add to cart and click on the cart icon on the top right corner of
            your screen and proceed to checkout and input your location. Note:
            Jazzy’s Burger services are currently only available in Lagos at the
            time this post was made.
          </p>
          <p>
            Once you’re done entering your location, proceed to make your
            payment. The app has five payment options, pay with card, bank,
            transfer, USSD, and Visa QR. This means you can choose the most
            convenient form of payment that works for you.
          </p>
          <p>
            An automatic delivery fee of N3,000 is added to the total price of
            your order before payment. After completing your order, you have a
            guarantee of receiving your order within the hour as part of our
            efforts to foster reliability in a saturated market.
          </p>
          <p className="jazzy-links">
            <b>Twitter</b>:{" "}
            <a
              href="https://twitter.com/jazzysburger"
              rel="noreferrer"
              target={"_blank"}
            >
              {" "}
              https://twitter.com/jazzysburger
            </a>
          </p>
          <p className="jazzy-links">
            <b>Instagram</b>:{" "}
            <a
              href="https://www.instagram.com/jazzysburger"
              rel="noreferrer"
              target={"_blank"}
            >
              {" "}
              https://www.instagram.com/jazzysburger{" "}
            </a>
          </p>
          <p className="jazzy-links">
            <b>Email</b>:{" "}
            <a
              href=" mailto: jazzysjuicyburger@gmail.com"
              rel="noreferrer"
              target={"_blank"}
            >
              {" "}
              jazzysjuicyburger@gmail.com{" "}
            </a>
          </p>
          <p className="jazzy-links">
            <b>Website</b>:{" "}
            <a
              href="https://jazzysburger.com/"
              rel="noreferrer"
              target={"_blank"}
            >
              {" "}
              https://jazzysburger.com/{" "}
            </a>
          </p>
          <p>
            <b>Country</b> - Nigeria
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleSection;
