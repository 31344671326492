import React from "react";
import "./_ResponsiveError.scss";

const ResponsiveError = () => {
  return (
    <div className="responsive-error-page">
      <h5 className="error">Not available at this screen size.</h5>
      <p className="error2">View on desktop instead</p>
    </div>
  );
};

export default ResponsiveError;
