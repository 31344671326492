import React, { useState } from "react";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import banner from "./images/banner.png";
import "./_Main.scss";

const UserSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <Wrapper>
      <div className="vaulthill__user-section">
        <header>
          <span>
            <h5 className="light-text font-semibold">
              User-Friendly Job Search for Vault Hill's Metaverse Website.
            </h5>
          </span>
          <span>
            <p className="text-medium font-semibold light-text">Job search</p>
            <p className="text-small light-text font-medium">
              As part of our design for Vault Hill's metaverse website, we
              created a user-friendly job search page to help connect job
              seekers with exciting opportunities in this innovative field.
            </p>
          </span>
        </header>
        <div>
          {!loaded && <BlurLoader />}
          <img src={banner} alt="" onLoad={StuffLoaded} />
        </div>
      </div>
    </Wrapper>
  );
};

export default UserSection;
