import "./NotificationBar.css";
import closeX from "./images/fi-br-cross-small.svg";

const NotificationBar = () => {
  return (
    <div className="notification-bar">
      <div className="uigrid">
        <div className="uigrid-12 notification-bar-content">
          <p>
            Join us today for a 2hr class on “web technologies for business
            development”{" "}
            <span className="notification-content-link">
              <a href="https://">Sign up for free here</a>
            </span>
          </p>
          <div className="nav-close-x">
            <img src={closeX} alt="Close Button" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
