import React from "react";
import ProjectItem from "./ProjectItem";
import "./Main.css";
import { useNavigate } from "react-router-dom";

const ProjectSection = () => {
  const navigate = useNavigate();
  return (
    <div className="project-section">
      <div className="project-section-header">
        <h1>Our Recent</h1>
        <h1>Projects</h1>
      </div>
      <div className="projects">
        <ProjectItem
          id="yaaaga"
          title="Yaaaga!"
          description="Ecommerce & Lifestyle"
          url="http://#0"
          display="ltr"
          onClick={() => navigate("/caseStudy/yaaaga")}
        />
        <ProjectItem
          id="vaulthill"
          title="Vault Hill"
          description="Metaverse & Blockchain Technology"
          url="http://#0"
          display="rtl"
          onClick={() => navigate("/caseStudy/vaulthill")}
        />
        <ProjectItem
          id="ipaybtc"
          title="iPayBTC"
          description="Cryptocurrency Exchange"
          url="http://#0"
          display="ltr"
        />
        <ProjectItem
          id="ead"
          title="Eve After Dark"
          description="Ecommerce & Lifestyle"
          url="http://#0"
          display="rtl"
          onClick={() => navigate("/caseStudy/ead")}
        />
        <ProjectItem
          id="jazzy"
          title="Jazzy's Burger"
          description="Ecommerce & Lifestyle"
          url="http://#0"
          display="ltr"
          onClick={() => navigate("/caseStudy/jazzysburger")}
        />
        <ProjectItem
          id="lynk"
          title="Lynk"
          description="Social Media"
          url="http://#0"
          display="rtl"
          onClick={() => navigate("/caseStudy/lynk")}
        />
      </div>
    </div>
  );
};

export default ProjectSection;
