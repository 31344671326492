import React from "react";
import ThanksSection from "../Yaaaga/ThanksSection/Main";
import LandingSection from "./LandingSection/Main";
import LogoSection from "./LogoSection/Main";
import Onboarding from "./Onboarding/Main";
import TopSection from "./TopSection/Main";

const Lynk = () => {
  return (
    <>
      <TopSection />
      <LogoSection />
      <LandingSection />
      <Onboarding />
      <ThanksSection />
    </>
  );
};

export default Lynk;
