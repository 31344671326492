import React from "react";
import PropTypes from "prop-types";
const InterfaceIcon = ({ onClick, size = 20 }) => {
  return (
    <svg
      style={{ width: size, height: size }}
      onClick={onClick}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 33H26C31 33 33 31 33 26V20C33 15 31 13 26 13H20C15 13 13 15 13 20V26C13 31 15 33 20 33Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 19.75C19 18.75 20.63 18.75 21.64 19.75"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.36 19.75C25.36 18.75 26.99 18.75 28 19.75"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4 28.6998H26.6C27.1 28.6998 27.5 28.2998 27.5 27.7998C27.5 25.3098 25.49 23.2998 23 23.2998C20.51 23.2998 18.5 25.3098 18.5 27.7998C18.5 28.2998 18.9 28.6998 19.4 28.6998Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="1" width="44" height="44" rx="6" stroke="white" />
    </svg>
  );
};
InterfaceIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};
export default InterfaceIcon;
