import React from "react";
import "./Main.css";
import TextSketch from "./images/Vector.svg";
const TopSection = () => {
  return (
    <div className="services__top-section">
      <div>
        <div className="services__top-section-header">
          <header>
            <h3>
              We are an extension
              <div className="services__header-text-sketch">
                <img src={TextSketch} alt="" />
              </div>{" "}
              of your team
            </h3>
            <p>We are a reliable and efficient addition to your team</p>
          </header>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
