import React from 'react'
import TopLHS from './TopLHS'
import TopRHS from './TopRHS'
import "./Main.css"
import vaulthill from './images/VaultHill_logo.png'
import Yaaaga from './images/ylogo.png'
import EAD from './images/eve_after_dark-logo.png'
const TopSection = () => {
  return (
    <div className='about__top-section'>
    <div className='uigrid'>
      <TopLHS />
      <TopRHS />
    </div>
    <div className='uigrid'>
    <div className='uigrid-8 about__top-section-lower'>
    <div className='about__top-section-header'>
        <h3>Our Clients</h3>
    </div>
    <div className='about__top-section-logos'>
        <img className='about__vaulthill-logo' src={vaulthill} alt="/" />
        <img className='about__yaaaga-logo' src={Yaaaga} alt="/" />
        <img className='about__EAD-logo' src={EAD} alt="/" />
    </div>
    </div>
    </div>
    </div>
  )
}

export default TopSection