import React from "react";
import ThanksSection from "../Yaaaga/ThanksSection/Main";
import CartandTracking from "./Cart&Tracking/Main";
import Catalogue from "./Catalogue/Main";
import LandingScreen from "./LandingScreen/Main";
import Screens from "./Screens/Main";
import SolutionSection from "./SolutionSection/Main";
import TimelineSection from "./Timeline/Main";
import TopSection from "./TopSection/Main";
import TypographySection from "./Typography&Color/Main";

const EAD = () => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <TopSection />
      <SolutionSection />
      <TimelineSection />
      <TypographySection />
      <LandingScreen />
      <Catalogue />
      <CartandTracking />
      <Screens />
      <ThanksSection />
    </div>
  );
};

export default EAD;
