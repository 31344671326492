import React from "react";
import ForwardIcon from "../../../UI/IconComponents/ForwardIcon";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import gridbox1 from "./images/gridbox1.png";
import gridbox2 from "./images/gridbox2.png";
import gridbox3 from "./images/gridbox3.png";
import bitmap from "./images/Bitmap.png";
import "./_Main.scss";
import { useState } from "react";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";

const CompanySection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <Wrapper className="Vaulthill__company-section">
      <div className="vaulthill__company-background">
        <div>
          <div className="company-background__flexbox">
            <h6>Company Background</h6>
            <span style={{ width: 650 }}>
              <p className="text-medium font-normal lite-text">
                Vault Hill is a blockchain-based metaverse that incorporates
                extended reality and focuses on basic human instincts. The
                platform provides a non-fungible token marketplace, avatars, and
                consulting services to help content creators and developers
                monetize their creations in a secure ecosystem.
              </p>
            </span>
          </div>
          <div className="company-background__flexbox">
            <h6>Goal</h6>
            <div style={{ width: 650 }}>
              <p className="text-medium font-normal lite-text">
                Our goal is to design a website for Vault Hill that showcases
                their innovative blockchain-based metaverse and services, while
                also driving business growth and establishing the company as a
                leader in the field of extended reality and blockchain-based
                solutions.
              </p>
              <div className="company-section-launch-btn">
                <p className="text-medium-sm dark-text font-normal">
                  Launch Project
                </p>
                <ForwardIcon size={35} hoverColor={"rgb(0, 186, 128)"} />
              </div>
            </div>
          </div>
        </div>
        <div className="company-section__grid-box-container">
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox1} alt="/" onLoad={StuffLoaded} />
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox2} alt="/" onLoad={StuffLoaded} />
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox3} alt="/" onLoad={StuffLoaded} />
          </figure>
        </div>
      </div>
      <img className="company-section__bitmap" src={bitmap} alt="/" />
    </Wrapper>
  );
};

export default CompanySection;
