import React from "react";
import "./LynkHeader.scss";

const LynkHeader = ({ page, title, subtitle }) => {
  return (
    <div>
      <div className="lynk__header">
        <span className="lynk__header-top">
          <p className="text-small font-bold light-text">{page}</p>
        </span>
        <div className="lynk__header-lower">
          <span>{title}</span>
          <span className="text-small font-medium light-text opacity">
            {subtitle}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LynkHeader;
