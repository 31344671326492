import React, { useState } from "react";
import "./Main.css";

const TopSection = () => {
  const [newDesignExists, setNewDesignExists] = useState(false);
  return (
    <div className="pricing__top-section" style={{ height: "100vh" }}>
      <div className="pricing__top-section-upper">
        <div className=" pricing__top-section-header">
          <header
            style={{ width: !newDesignExists ? "80%" : "", margin: "0 auto" }}
          >
            <h3>
              {newDesignExists
                ? "Ready to get started?"
                : "This Page Is Temporarily Unavailable"}
            </h3>
            <p>
              {newDesignExists
                ? "Let’s help you choose a suitable plan for your team"
                : "Contact us for more details, let’s help you choose a suitable plan for your team"}
            </p>
          </header>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
