import React from "react";
import NotificationBar from "./NotificationBar";
import Button from "../Button/Button";
import Logo from "./images/logo_w_icon.svg";
import MobileMenu from "./images/mobile-menu.svg";
import { Link, useLocation } from "react-router-dom";

import "./NavBar.css";

const NavBar = ({ showNotificationBar }) => {
  const location = useLocation();

  return (
    <div className="topsection-navbar">
      {showNotificationBar && <NotificationBar />}
      <div className="navbar-main">
        <div className="uigrid navbar-content">
          <div className="uigrid-2 navbar-logo">
            <Link to="/">
              <img className="logo" src={Logo} alt="Awa Digital Logo" />
            </Link>
          </div>
          <div className="uigrid-8 navbar-links">
            <ul>
              <li>
                <Link
                  to="/aboutUs"
                  className={
                    location.pathname.substring(1) === "aboutUs"
                      ? "nav-active hoverable"
                      : "hoverable"
                  }
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className={
                    location.pathname.substring(1) === "services"
                      ? "nav-active hoverable"
                      : "hoverable"
                  }
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/pricing"
                  className={
                    location.pathname.substring(1) === "pricing"
                      ? "nav-active hoverable"
                      : "hoverable"
                  }
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className={
                    location.pathname.includes("blog")
                      ? "nav-active hoverable"
                      : "hoverable"
                  }
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contactUs"
                  className={
                    location.pathname.substring(1) === "contactUs"
                      ? "nav-active hoverable"
                      : "hoverable"
                  }
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="uigrid-2 navbar-button-container">
            <Button value="Book an appointment" class="navbar-btn">
              Book an appointment
            </Button>
          </div>
          <div className="navbar-mobile">
            <div className="navbar-mobile-menu">
              <img src={MobileMenu} alt="Awa Digital Menu" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NavBar.defaultProps = {
  showNotificationBar: true,
};

export default NavBar;
