import React, {useState} from 'react'
import BlurLoader from '../../../UI/LazyLoader/BlurLoader'
import Wrapper from '../../../UI/Wrapper/Wrapper'
import banner from './images/banner.png'
import './_Main.scss'

const FontSection = () => {
    const [loaded, setloaded] = useState(false);

    const StuffLoaded = () => {
      setTimeout(() => {
        setloaded(true);
      }, 6000);
    };
  return (
   <Wrapper>
     <div className='vaulthill__font-section'>
       <header>
       <p className='text-medium-sm font-semibold dark-text'>Font & Color</p>
       </header>
       <div  className='vaulthill__font-section-header'>
        <div>
            <p>Poppins</p>
        </div>
        <div>
          <h6>Main Font</h6>
          <span>
            <p className='font-normal dark-text'>Regular</p>
            <p className='font-medium dark-text'>Medium</p>
            <p className='font-semibold dark-text'>Semibold</p>
            <p className='font-bold dark-text'>Bold</p>
          </span>
        </div>
       </div>
       <figure>
       {!loaded && <BlurLoader />}
         <img src={banner} alt='/' onLoad={StuffLoaded} />
       </figure>
     </div>
   </Wrapper>
  )
}

export default FontSection