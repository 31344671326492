import React from "react";
import BlogItem from "../../LandingPage/BlogSection/BlogItem";
import vhJimi from "./images/blog-hero.png";
import blogImage from "./images/blog-image-1.webp";
import "./Main.css";

const ArticleSection = () => {
  return (
    <div className="blog__article-section">
      <div className="uigrid">
        <div className="uigrid-5 blog__article-section-header">
          <h3>Others.</h3>
        </div>
        <div className="uigrid-5 blog-text">
          <p>
            Tech news and helpful tips for clients and individuals looking to
            build or building products or in the tech industry.
          </p>
        </div>
      </div>
      <div className="uigrid blog__items-container">
        <BlogItem
          style={{
            height: "854px",
          }}
          image={vhJimi}
          date="May 2, 2022"
          title="Vault Hill raises $2.1m to build its human-centric metaverse leveraging urban planning principles"
        />
        <div className="uigrid-6 blog__items-container-flex">
          <BlogItem
            image={blogImage}
            date="May 2, 2022"
            title="Vault Hill raises $2.1m to build its human-centric metaverse leveraging urban planning principles"
          />
          <BlogItem
            image={blogImage}
            date="May 2, 2022"
            title="Vault Hill raises $2.1m to build its human-centric metaverse leveraging urban planning principles"
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleSection;
