import React, { useState } from "react";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";

const DesignItem = ({ phone, box, title, subtitle, direction }) => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <div className="jazzy__design-item">
      <div style={{ flexDirection: direction }} className="flex-container">
        <figure className="figure1">
          {!loaded && <BlurLoader />}
          <img src={phone} alt="/" onLoad={StuffLoaded} />
        </figure>
        <div>
          <span>
            <h3 className="text-large font-semibold void-text">{title}</h3>
            <p className="text-medium font-normal void-text opacity">
              {subtitle}
            </p>
          </span>
          <figure className="figure2">
            <img src={box} alt="/" />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default DesignItem;
