import React from "react";

const EadTitle = ({ title, description }) => {
  return (
    <div className="ead__title">
      <p className="text-small font-bold light-text">{title}</p>
      <p className="text-small font-medium light-text">{description}</p>
    </div>
  );
};

export default EadTitle;
