import React from "react";
import CompanySection from "./CompanySection/Main";
import DesignSection from "./DesignSection/Main";
import FontSection from "./FontSection/Main";
import GridSection from "./GridSection/Main";
import JourneySection from "./JourneySection/Main";
import TimelineSection from "./TimelineSection/Main";
import TopSection from "./TopSection/Main";
import UserSection from "./UserSection/Main";
import ThanksSection from "../Yaaaga/ThanksSection/Main";

const VaultHill = () => {
  return (
    <>
      <TopSection />
      <CompanySection />
      <TimelineSection />
      <FontSection />
      <JourneySection />
      <UserSection />
      <DesignSection />
      <GridSection />
      <ThanksSection />
    </>
  );
};

export default VaultHill;
