import React, { useState } from "react";
import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import vector2 from "./images/group2.svg";
import vector1 from "./images/group3.svg";
import "./Main.css";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";

const LosSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <div className="los-section-container">
      <div className="los-section-container-inner">
        <div className="uigrid los-section-box">
          <header className="uigrid-4">
            <h3>Software Development</h3>
            <p>
              Get exclusive access to a professional team of standby software
              developers to handle medium/large scale projects in your company.
            </p>
          </header>
          <div className="uigrid-6 los-section-box-img-container">
            {!loaded && <BlurLoader />}
            <img
              className="los-section-box-img"
              src={image1}
              alt="/"
              onLoad={StuffLoaded}
            />
            <img className="span-vector" src={vector1} alt="/" />
          </div>
        </div>
        <div className="uigrid los-section-box">
          <div className="uigrid-6 los-section-middle-img">
            {!loaded && <BlurLoader />}
            <img
              className="los-section-box-img"
              src={image2}
              alt="/"
              onLoad={StuffLoaded}
            />
            <img className="middle-vector" src={vector2} alt="/" />
          </div>
          <header className="uigrid-4 los-section-middle-text">
            <h3>UX/UI Design</h3>
            <p>
              Get exceptional teams with experience accross multiple indsutries
              to handle ideation, user experience and user interface designs for
              your company, brand and product.
            </p>
          </header>
        </div>
        <div className="uigrid los-section-box">
          <header className="uigrid-4">
            <h3>Web/Mobile Development</h3>
            <p>
              Get a team of specialists to build and manage mobile and web
              development projects with your in-house team.
            </p>
          </header>
          <div className="uigrid-6 los-section-box-img-container">
            {!loaded && <BlurLoader />}
            <img
              className="los-section-box-img"
              src={image3}
              alt="/"
              onLoad={StuffLoaded}
            />
            <img className="span-vector" src={vector1} alt="/" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LosSection;
