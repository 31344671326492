import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import LynkHeader from "../LynkHeader";
import banner from "./images/banner.png";
import phone1 from "./images/phone1.png";
import phone2 from "./images/phone2.png";
import flexbox1 from "./images/flexbox1.png";
import flexbox2 from "./images/flexbox2.png";
import "./_Main.scss";

const LandingSection = () => {
  return (
    <div className="lynk__landing-page">
      <Wrapper className="lynk__landing-page-top">
        <div className="top-container">
          <LynkHeader
            page="Landing page"
            title="From Different Platforms to One Simple Link."
            subtitle="We designed a visually appealing and user-friendly website for Lynk, featuring a simple yet colorful design that immediately captures users' attention. The intuitive interface makes it effortless for users to get started with creating their all-in-one link for social media profiles, blogs, and other relevant information, streamlining their online presence."
          />
          <figure>
            <img src={banner} alt="/" />
            <img src={phone1} alt="/" />
            <img src={phone2} alt="/" />
          </figure>
        </div>
      </Wrapper>
      <figure className="landing-page-lower">
        <img src={flexbox1} alt="/" />
        <img src={flexbox2} alt="/" />
      </figure>
    </div>
  );
};

export default LandingSection;
