import React from "react";
import ProfileImage from "../../UI/ProfileImage/ProfileImage";
import ProfileText from "../../UI/ProfileText/ProfileText";
import donJazzy from "./images/donJazzy.png";
import EAD from "./images/EAD.png";
import "./Main.css";

const ClientSection = () => {
  return (
    <div className="about__client-section">
      <div className="uigrid">
        <div className="uigrid-5 about__client-section-header">
          <h3>What our</h3>
          <h3>clients have to say</h3>
        </div>
      </div>
      <div className="uigrid about__client-section-main">
        <ProfileImage image={donJazzy} key={Math.random().toString()} />
        <ProfileText
          style={{
            gridColumnEnd: "-1",
          }}
          title="“Outdid Our Expectations”"
          description="Money well spent. As someone who has worked with several companies in the past, i must commend the team at Awa Digital. They delivered on time as discussed and the app has been up for several months now with zero downtime. Impressive"
          personsName="Don Jazzy"
          position="Senior Vice President of Sales"
        />
      </div>
      <div className="uigrid about__client-section-main">
        <ProfileText
          title="Best Agency, Great Price"
          description="I spent several weeks searching for a good team to work within my budget. Awa Digital stood out to me from the onset, our negotiations were swift and detailed. They have a great work ethic and unique approach to customer relations."
          personsName="Chioma Nnadi"
          position="Eve After Dark"
        />
          <ProfileImage
            image={EAD}
            key={Math.random().toString()}
            style={{
              gridColumnEnd: "-1",
            }}
          />
      </div>
    </div>
  );
};

export default ClientSection;
