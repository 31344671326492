import React, { useState, useEffect } from "react";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";

import "./BlogItem.css";

const BlogItem = (props) => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  useEffect(() => {
    StuffLoaded();
  }, []);

  const truncateTitle = (str) => {
    return str.length > 65 ? str.substring(0, 62) + "..." : str;
  };
  return (
    <div className="blog-item uigrid-6 hoverable">
      <div style={props.style} className="blog-item-image">
        {!loaded ? <BlurLoader /> : <img src={props.image} alt={props.title} />}
      </div>
      <div className="blog-item-date">
        <p>{props.date}</p>
      </div>
      <div className="blog-item-title">
        <h3 onClick={props.onClick}>{truncateTitle(props.title)}</h3>
      </div>
    </div>
  );
};

export default BlogItem;
