import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import "./_Main.scss"

const CoreSection = () => {
  return (
    <Wrapper>
      <div className="yaaaga__core-section-inner">
        <div>
          <h6>AWA’s core process that lead each project</h6>
          <p>
            Yaaaga worked with Awa to increase it’s growth even more and explore
            it’s digital potential.
          </p>
        </div>
        <div>
          <span>
            <p>01.</p>
            <h5>Problem definition & Primary / secondary research</h5>
          </span>
          <span>
            <p>02.</p>
            <h5>Competitor analysis</h5>
          </span>
          <span>
            <p>03.</p>
            <h5>Personas & User flows</h5>
          </span>
          <span>
            <p>04.</p>
            <h5>Sketch & Low - Fidelity prototypes</h5>
          </span>
          <span>
            <p>05.</p>
            <h5>High fidelity prototypes</h5>
          </span>
          <span>
            <p>06.</p>
            <h5>Development</h5>
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

export default CoreSection;
