import Index from "./LandingPage/Index";
import "./Fonts/Fonts.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./AboutUsPage/AboutUs";
import ContactUs from "./ContactUsPage/ContactUs";
import Blog from "./Blog/Blog";
import Pricing from "./PricingPage/Pricing";
import Services from "./ServicesPage/Services";
import Vaulthill from "./Blog/BlogPages/VaultHill/Vaulthill";
import JazzyBurger from "./Blog/BlogPages/JazzyBurger/JazzyBurger";
import BlogIndex from "./Blog/Index"
import CaseStudies from "./CaseStudies/CaseStudies";

function App() {
  return (
  <div>
    <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/aboutUs" element={ <AboutUs />} />
          <Route path="/contactUs" element={ <ContactUs />} />
          <Route path="/blog" element={ <BlogIndex/>} >
            <Route path="" element={ <Blog />} />
            <Route path="jazzyBurger" element={ <JazzyBurger />} />
            <Route path="vaultHill" element={ <Vaulthill />} />
          </Route>
          <Route path="/pricing" element={ <Pricing />} />
          <Route path="/services" element={ <Services />} />
          <Route path="/caseStudy/*" element={ <CaseStudies />} />
        </Routes>
      </Router>
  </div>
  )
}

export default App;
