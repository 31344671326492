import React from "react";
import "./Main.css";
const Socialicons = () => {
  return (
    <div className="social-icons-lite">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/awadigital"
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.25 1.30859C12.25 0.953125 11.9219 0.625 11.5664 0.625H0.65625C0.300781 0.625 0 0.953125 0 1.30859V12.2188C0 12.5742 0.300781 12.875 0.65625 12.875H6.53516V8.14453H4.92188V6.28516H6.53516V4.91797C6.53516 3.35938 7.49219 2.48438 8.91406 2.48438C9.57031 2.48438 10.1719 2.53906 10.3359 2.56641V4.20703H9.35156C8.58594 4.20703 8.44922 4.58984 8.44922 5.10938V6.28516H10.2812L10.0352 8.14453H8.44922V12.875H11.5664C11.9219 12.875 12.25 12.5742 12.25 12.2188V1.30859Z"
            fill="white"
          />
        </svg>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/awa.digital"
      >
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.125 3.60547C5.375 3.60547 3.98047 5.02734 3.98047 6.75C3.98047 8.5 5.375 9.89453 7.125 9.89453C8.84766 9.89453 10.2695 8.5 10.2695 6.75C10.2695 5.02734 8.84766 3.60547 7.125 3.60547ZM7.125 8.80078C6.00391 8.80078 5.07422 7.89844 5.07422 6.75C5.07422 5.62891 5.97656 4.72656 7.125 4.72656C8.24609 4.72656 9.14844 5.62891 9.14844 6.75C9.14844 7.89844 8.24609 8.80078 7.125 8.80078ZM11.1172 3.49609C11.1172 3.08594 10.7891 2.75781 10.3789 2.75781C9.96875 2.75781 9.64062 3.08594 9.64062 3.49609C9.64062 3.90625 9.96875 4.23438 10.3789 4.23438C10.7891 4.23438 11.1172 3.90625 11.1172 3.49609ZM13.1953 4.23438C13.1406 3.25 12.9219 2.375 12.2109 1.66406C11.5 0.953125 10.625 0.734375 9.64062 0.679688C8.62891 0.625 5.59375 0.625 4.58203 0.679688C3.59766 0.734375 2.75 0.953125 2.01172 1.66406C1.30078 2.375 1.08203 3.25 1.02734 4.23438C0.972656 5.24609 0.972656 8.28125 1.02734 9.29297C1.08203 10.2773 1.30078 11.125 2.01172 11.8633C2.75 12.5742 3.59766 12.793 4.58203 12.8477C5.59375 12.9023 8.62891 12.9023 9.64062 12.8477C10.625 12.793 11.5 12.5742 12.2109 11.8633C12.9219 11.125 13.1406 10.2773 13.1953 9.29297C13.25 8.28125 13.25 5.24609 13.1953 4.23438ZM11.8828 10.3594C11.6914 10.9062 11.2539 11.3164 10.7344 11.5352C9.91406 11.8633 8 11.7812 7.125 11.7812C6.22266 11.7812 4.30859 11.8633 3.51562 11.5352C2.96875 11.3164 2.55859 10.9062 2.33984 10.3594C2.01172 9.56641 2.09375 7.65234 2.09375 6.75C2.09375 5.875 2.01172 3.96094 2.33984 3.14062C2.55859 2.62109 2.96875 2.21094 3.51562 1.99219C4.30859 1.66406 6.22266 1.74609 7.125 1.74609C8 1.74609 9.91406 1.66406 10.7344 1.99219C11.2539 2.18359 11.6641 2.62109 11.8828 3.14062C12.2109 3.96094 12.1289 5.875 12.1289 6.75C12.1289 7.65234 12.2109 9.56641 11.8828 10.3594Z"
            fill="white"
          />
        </svg>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/awadigitalco"
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5508 2.90625C13.0977 2.49609 13.5898 2.00391 13.9727 1.42969C13.4805 1.64844 12.9062 1.8125 12.332 1.86719C12.9336 1.51172 13.3711 0.964844 13.5898 0.28125C13.043 0.609375 12.4141 0.855469 11.7852 0.992188C11.2383 0.417969 10.5 0.0898438 9.67969 0.0898438C8.09375 0.0898438 6.80859 1.375 6.80859 2.96094C6.80859 3.17969 6.83594 3.39844 6.89062 3.61719C4.51172 3.48047 2.37891 2.33203 0.957031 0.609375C0.710938 1.01953 0.574219 1.51172 0.574219 2.05859C0.574219 3.04297 1.06641 3.91797 1.85938 4.4375C1.39453 4.41016 0.929688 4.30078 0.546875 4.08203V4.10938C0.546875 5.50391 1.53125 6.65234 2.84375 6.92578C2.625 6.98047 2.35156 7.03516 2.10547 7.03516C1.91406 7.03516 1.75 7.00781 1.55859 6.98047C1.91406 8.12891 2.98047 8.94922 4.23828 8.97656C3.25391 9.74219 2.02344 10.207 0.683594 10.207C0.4375 10.207 0.21875 10.1797 0 10.1523C1.25781 10.9727 2.76172 11.4375 4.40234 11.4375C9.67969 11.4375 12.5508 7.08984 12.5508 3.28906C12.5508 3.15234 12.5508 3.04297 12.5508 2.90625Z"
            fill="white"
          />
        </svg>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/awadigital"
      >
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 0.96875C3.25391 0.96875 0.21875 4.03125 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7188 14.5312 13.7812 11.4961 13.7812 7.75C13.7812 4.03125 10.7188 0.96875 7 0.96875ZM11.457 4.11328C12.2773 5.09766 12.7695 6.35547 12.7695 7.72266C12.5781 7.66797 10.6641 7.28516 8.75 7.53125C8.58594 7.14844 8.44922 6.82031 8.23047 6.38281C10.3906 5.50781 11.3477 4.27734 11.457 4.11328ZM10.8281 3.42969C10.7188 3.59375 9.84375 4.76953 7.79297 5.53516C6.83594 3.78516 5.79688 2.36328 5.63281 2.14453C7.46484 1.70703 9.40625 2.17188 10.8281 3.42969ZM4.51172 2.52734C4.67578 2.74609 5.71484 4.16797 6.67188 5.89062C3.96484 6.60156 1.58594 6.60156 1.3125 6.57422C1.69531 4.79688 2.89844 3.29297 4.51172 2.52734ZM1.20312 7.77734C1.20312 7.72266 1.20312 7.66797 1.20312 7.58594C1.44922 7.61328 4.26562 7.64062 7.13672 6.76562C7.32812 7.09375 7.46484 7.42188 7.62891 7.75C5.52344 8.35156 3.60938 10.0469 2.67969 11.6328C1.75 10.6211 1.20312 9.25391 1.20312 7.77734ZM3.41797 12.3438C4.04688 11.1133 5.6875 9.5 8.01172 8.70703C8.83203 10.8125 9.16016 12.5898 9.24219 13.1094C7.38281 13.9023 5.14062 13.6836 3.41797 12.3438ZM10.2266 12.5625C10.1719 12.2344 9.84375 10.5391 9.10547 8.43359C10.9102 8.16016 12.4961 8.625 12.7148 8.67969C12.4414 10.293 11.5117 11.6875 10.2266 12.5625Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  );
};

export default Socialicons;
