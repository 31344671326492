import React from "react";
import ScrollToTopOnMount from "../../../Components/ScrollToTop";
import ArticleSection from "./ArticleSection/Main";
import TopSection from "./TopSection/Main";

const Vaulthill = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <TopSection />
      <ArticleSection />
    </>
  );
};

export default Vaulthill;
