import React from "react";
import "./Main.css";
import StoryContent from "./StoryContent";
const StorySection = () => {
  return (
    <div className="about__story-section">
      <div className="uigrid about__story-section-top">
        <header className=" uigrid-6 about__story-section-header">
          <h3>Awa Story</h3>
          <p>
            Our story is one of reliability and efficiency. We are constantly
            improving and innovating. In addition to that, we have spent the
            last few years researching and learning how to best serve our
            clients and deliver quality products to them.
          </p>
        </header>
      </div>
      <div className="uigrid">
        <StoryContent />
      </div>
    </div>
  );
};

export default StorySection;
