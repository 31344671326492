import React, { useState, useEffect } from "react";
import "./ProjectImage.css";
import Yaaaga from "./images/yag-img.jpg";
import VaultHill from "./images/vh-img.jpg";
import iPay from "./images/ipay.jpg";
import Ead from "./images/ead-img.png";
import Jazzy from "./images/jazzy-img.png";
import Lynk from "./images/lynk-img.png";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";

const ProjectImage = (props) => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 5000);
  };

  let images = {
    yaaaga: Yaaaga,
    vaulthill: VaultHill,
    ipaybtc: iPay,
    ead: Ead,
    jazzy: Jazzy,
    lynk: Lynk,
  };
  const [image, setImage] = useState(Yaaaga);

  // eslint-disable-next-line
  useEffect(() => {
    let currentImage = images[props.id];
    setImage(currentImage);
  });

  return (
    <div className="uigrid-5 project-image">
      <div className="project-image-container">
        {!loaded && <BlurLoader />}
        <img src={image} alt="" onLoad={StuffLoaded} />
      </div>
    </div>
  );
};

export default ProjectImage;
