import React from "react";
import "./Main.css";
import TeamCard from "./TeamCard";
import TeamContent from "./TeamContent";
import legal2 from "./images/legal2.png";
import mobile1 from "./images/mobile1.png";
import more from "./images/more+.png";
import web from "./images/web.png";

const TeamSection = () => {
  return (
    <div className="about__team-section">
      <div className="uigrid">
        <div className="uigrid-6 about__team-section-top">
          <header className="about__team-section-header">
            <h3>Our Team</h3>
            <p>
              Our team is comprised of industry professionals who have years of
              experience in building awesome large scale products. Our Quality
              Assurance team works hand-in-hand with our Design and development
              team.
            </p>
          </header>
        </div>
      </div>
      <div className="about__team-section_LTR">
        <div className="uigrid about__team-section-inner_LTR">
          <TeamContent />
        </div>
      </div>
      <div className="about__team-section_RTL">
        <div className="uigrid about__team-section-inner_RTL">
        <div className="uigrid-12 about__team-section-inner_RTL_container ">
          <TeamCard
            avatar={legal2}
            personsName={"Sylvia O."}
            position={"Legal"}
            key={Math.random().toString()}
          />
          <TeamCard
            avatar={mobile1}
            personsName={"Victor M."}
            position={"Mobile"}
            key={Math.random().toString()}
          />
           <TeamCard
            avatar={web}
            personsName={"Kobi E."}
            position={"Frontend"}
            key={Math.random().toString()}
          />
          <TeamCard
            avatar={more}
            personsName={"+ More"}
            key={Math.random().toString()}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
