import React from "react";
import ThanksSection from "../Yaaaga/ThanksSection/Main";
import DesignSection from "./DesignSection/Main";
import LogoSection from "./LogoSection/Main";
import Screens from "./Screens/Main";
import TopSection from "./TopSection/Main";
import Typography from "./Typography/Main";

const JazzysBurger = () => {
  return (
    <>
      <TopSection />
      <LogoSection />
      <Typography />
      <DesignSection />
      <Screens />
      <ThanksSection />
    </>
  );
};

export default JazzysBurger;
