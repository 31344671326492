import React from "react";
import ProjectContent from "./ProjectContent";
import ProjectImage from "./ProjectImage";
import "./ProjectItem.css";

const ProjectItem = (props) => {
  if (props.display === "ltr") {
    return (
      <div className="project-item ltr-project-item">
        <div className="uigrid">
          <ProjectContent
            title={props.title}
            description={props.description}
            url={props.url}
            onClick={props.onClick}
          />
          <ProjectImage id={props.id} />
        </div>
      </div>
    );
  }
  return (
    <div className="project-item rtl-project-item">
      <div className="uigrid">
        <ProjectImage id={props.id} />
        <ProjectContent
          title={props.title}
          description={props.description}
          url={props.url}
          onClick={props.onClick}
        />
      </div>
    </div>
  );
};

export default ProjectItem;
