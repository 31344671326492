import React from "react";
import ScrollToTopOnMount from "../Components/ScrollToTop";
import MainFooter from "../UI/Footer/MainFooter";
import NavBar from "../UI/NavBar/NavBar";
import FaqsSection from "./FaQs/Main";
import PricingSection from "./PricingSection/Main";
import TopSection from "./TopSection/Main";
import ResponsiveError from "../ErrorPage/ResponsiveError";

const Pricing = () => {
  const phoneScreenWidth = 1189;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;
  return (
    <div>
      <ScrollToTopOnMount />
      <NavBar />
      {isPhoneScreen ? (
        <ResponsiveError />
      ) : (
        <>
          <TopSection />
          {/* <PricingSection />
          <FaqsSection /> */}
        </>
      )}{" "}
      <div
        style={{
          paddingBottom: "50px",
          background: "white",
        }}
      >
        <MainFooter />
      </div>
    </div>
  );
};

export default Pricing;
