import React from "react";

const FontItem = ({ color, borderColor, bg_color, hexCode }) => {
  return (
    <div className="font-item">
      <span className="color-float">{color}</span>
      <div style={{ borderColor: borderColor }} className="ellipse-border">
        <span style={{ backgroundColor: bg_color }} className="ellipse"></span>
      </div>
      <span className="hex-float">
        <p>HEX</p>
        <p className="opacity">{hexCode}</p>
      </span>
    </div>
  );
};

export default FontItem;
