import React, { useState } from "react";
import ResearchImage from "./images/icon=research.svg";
import ResearchImageDark from "./images/icon=research-dark.svg";
import DiscussionImage from "./images/icon=discussion.svg";
import DiscussionImageDark from "./images/icon=discussion-dark.svg";
import IdeationImage from "./images/icon=ideation.svg";
import IdeationImageDark from "./images/icon=ideation-dark.svg";
import ImplementationImage from "./images/icon=implementation.svg";
import ImplementationImageDark from "./images/icon=implementation-dark.svg";

import "./ServiceItem.css";

const ServiceItem = (props) => {
  const images = {
    research: ResearchImage,
    "research-dark": ResearchImageDark,
    implementation: ImplementationImage,
    "implementation-dark": ImplementationImageDark,
    discussion: DiscussionImage,
    "discussion-dark": DiscussionImageDark,
    ideation: IdeationImage,
    "ideation-dark": IdeationImageDark,
  };

  let propImage = images[props.id];
  const [image, HoverImage] = useState(propImage);

  const mouseEnterHandler = () => {
    let id = props.id + "-dark";
    let propImage = images[id];
    HoverImage(propImage);
  };

  const mouseLeaveHandler = () => {
    let id = props.id;
    let propImage = images[id];
    HoverImage(propImage);
  };

  return (
    <div
      className="uigrid-3 service-item"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <div
        className="indicator-image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="service-title">
        <h5>{props.title}</h5>
      </div>
      <div className="service-description">
        <p>{props.description}</p>
      </div>
      <div className="service-link">
        <a href={props.url} className="hoverable">
          Learn More
        </a>
      </div>
    </div>
  );
};

export default ServiceItem;
