import React from "react";
import "./ContactForm.css";
import Button from "../../UI/Button/Button";

const ContactForm = () => {
  return (
    <div className="contact-form uigrid">
      <div className=" uigrid-8 contact-form-box">
        <div className="contact-form-top-inner">
          <h3>Get a quick estimate</h3>
          <p>
            Our time is in GMT +1. We usually respond within 3 hours. Unless
            within 9PM - 7AM
          </p>
        </div>
        <form action="" className="form__contact-form">
          <div className="contact-form-input-container">
            <input
              type="text"
              placeholder="Your Name"
              className="contact-form-input"
            />
            <input
              type="text"
              placeholder="Your Email"
              className="contact-form-input"
            />
            <input
              type="text"
              placeholder="Company Name"
              className="contact-form-input"
            />
            <input
              type="text"
              placeholder="Budget"
              className="contact-form-input"
            />
            <textarea
              type="text-area"
              placeholder="Describe your project details here..."
              className="contact-form-text"
              rows="3"
            />
          </div>
          <div className="contact__btn-container">
            <Button value="Book an appointment" class="header-link-btn" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
