import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import gridbox1 from "./images/gridbox1.png";
import gridbox2 from "./images/gridbox2.png";
import gridbox3 from "./images/gridbox3.png";
import overlay from "./images/gridbox-overlay.png";

import "./_Main.scss";

const LogoSection = () => {
  return (
    <Wrapper className="yaaga__logo-section">
      <div>
        <h6>App Icon & Logo</h6>
        <div>
          <span>
            <img src={gridbox1} alt="/" />
          </span>
          <span>
            <img src={gridbox2} alt="/" />
            <img src={overlay} alt="/" />
          </span>
          <img src={gridbox3} alt="/" />
        </div>
      </div>
    </Wrapper>
  );
};

export default LogoSection;
