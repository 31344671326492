import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import item1 from "./images/item1.png";
import item2 from "./images/item2.png";
import item3 from "./images/item3.png";
import item4 from "./images/item4.png";
import item5 from "./images/item5.png";
import OnboardingItem from "./OnboardingItem";
import "./_Main.scss";

const Onboarding = () => {
  return (
    <Wrapper className="yaaaga__onboarding">
     <p className="yaaaga-header text-large dark-text font-semibold">Onboarding</p>
      <div className="yaaaga__onboarding-item-container">
        <OnboardingItem
          image={item1}
          description="We designed the onboarding screen with clear and concise instructions, minimal required information and personalization in order to make the onboarding process smooth and simple."
        />
        <OnboardingItem
          direction={"row-reverse"}
          image={item2}
          description="We designed our app to be customizable to your preferences. With the ability to choose your own content and set preferences, our app empowers you to personalize your experience and make it work for you."
        />
        <OnboardingItem
          image={item3}
          description="We designed our app to simplify finding and following items of interest. With an intuitive search feature and user-friendly interface, users can stay informed on their favorite places and their latest offerings, resulting in increased engagement and satisfaction."
        />
        <OnboardingItem
        direction={"row-reverse"}
          image={item4}
          description="We designed our app to simplify the process of sending gifts to loved ones. With an easy-to-use interface and streamlined checkout process, users can quickly and conveniently send thoughtful gifts to friends and family anytime."
        />
        <OnboardingItem
          image={item5}
          description="We designed our app to simplify the process of listing and selling products. With our intuitive interface, you can easily create a product listing and receive real-time notifications when your items are purchased. Stay connected with your customers and grow your business with ease using our app."
        />
      </div>
    </Wrapper>
  );
};

export default Onboarding;
