import React from "react";
import ScrollToTopOnMount from "../Components/ScrollToTop";
import Footer from "../UI/Footer/Footer";
import NavBar from "../UI/NavBar/NavBar";
import ClientSection from "./ClientSection/Main";
import LosSection from "./LosSection/Main";
import TopSection from "./TopSection/Main";
import ResponsiveError from "../ErrorPage/ResponsiveError";

const Services = () => {
  const phoneScreenWidth = 1189;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;
  return (
    <div>
      <ScrollToTopOnMount />
      <NavBar />
      {isPhoneScreen ? (
        <ResponsiveError />
      ) : (
        <>
          <TopSection />
          <LosSection />
          <ClientSection />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Services;
