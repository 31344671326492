import React, { useState, useEffect } from "react";
import Image0 from "./images/Slide/0.jpg";
import Image1 from "./images/Slide/1.jpg";
import Image2 from "./images/Slide/2.jpg";
import Image3 from "./images/Slide/3.jpg";
import Image4 from "./images/Slide/4.jpg";
import "./Slider.css";

const Slider = () => {
  const [image, nextImage] = useState(Image0);

  const changeSliderImageHandler = () => {
    let images = [Image0, Image1, Image2, Image3, Image4];
    nextImage(getNextImage(images));
  };

  const getNextImage = (images) => {
    let currentIndex = images.indexOf(image);
    let nextIndex = (currentIndex + 1) % images.length;
    return images[nextIndex];
  };

  useEffect(() => {
    setTimeout(function () {
      changeSliderImageHandler();
    }, 5000); //wait 2 seconds
  });

  return (
    <div
      className="slider-image-holder"
      style={{ backgroundImage: `url(${image})` }}
      alt=""
      // onClick={changeSliderImageHandler}
    ></div>
  );
};

export default Slider;
