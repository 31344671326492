import React, { useState } from "react";
import ImpactInfo from "./ImpactInfo";
import ImpactImage from "./images/impact-img.jpg";
import "./Main.css";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";

const ImpactSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <div className="about__impact-section">
      <div className="uigrid">
        <div className="uigrid-6 about__impact-section-img-container">
          {!loaded && <BlurLoader />}
          <img src={ImpactImage} alt="" onLoad={StuffLoaded} />
        </div>
        <ImpactInfo />
      </div>
    </div>
  );
};

export default ImpactSection;
