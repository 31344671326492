import React from 'react'
import BlogSection from '../LandingPage/BlogSection/Main'
import ArticleSection from './ArticleSection/Main'
import TopSection from './TopSection/Main'

const Blog = () => {
  return (
    <>
    <TopSection />
    <BlogSection />
    <ArticleSection />
    </>
  )
}

export default Blog