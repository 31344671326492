import React from 'react'
import TextSketch from "./images/textsketch.svg";
import './TopLHS.css'

const TopLHS = () => {
  return (
        <div className="uigrid-6 about__top-lhs">
      <div className="about__lhs-inner">
        <div className="about__header-text">
          <h1>Stop hiring, start building.</h1>
          <div className="about__header-text-sketch">
            <img src={TextSketch} alt="" />
          </div>
        </div>
        <div className="about__header-description">
          <p>
          We focus on the core of your business and we would deliver the product, effectively and timely.
          </p>
        </div>
        <div className="TopLHS-ratings">
        <div className="TopLHS-rating-item">
          <div className="TopLHS-rating-rating hoverable">
            <h1>98%</h1>
          </div>
          <div className="TopLHS-rating-info">
            <p>
              Happy <br />
              Businesses
            </p>
          </div>
        </div>
        <div className="TopLHS-rating-item">
          <div className="TopLHS-rating-rating hoverable">
            <h1>43</h1>
          </div>
          <div className="TopLHS-rating-info ">
            <p>
              Completed <br />
              Projects
            </p>
          </div>
        </div>
        <div className="TopLHS-rating-item">
          <div className="TopLHS-rating-rating hoverable">
            <h1>90%</h1>
          </div>
          <div className="TopLHS-rating-info ">
            <p>
              Retainer <br />
              Success
            </p>
          </div>
        </div>
        <div className="TopLHS-rating-clear"></div>
      </div>
      </div>
    </div>
  )
}

export default TopLHS