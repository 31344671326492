import React from 'react'

const OnboardingItem = ({description, image, direction}) => {
 const styles = {
    flexDirection : direction
  }
  return (
    <div className='yaaaga__onboarding-item' style={styles}>
       <figure>
        <img src={image} alt="/" />
       </figure>
       <span>
       <p className='text-small dark-text font-normal'> {description} </p>
       </span>
    </div>
  )
}

export default OnboardingItem
