import React from "react";
import BlogItem from "./BlogItem.js";
import vhJimi from "./images/vh-jimi.png";
import banner from "../../Blog/BlogPages/JazzyBurger/images/banner.png";
import "./Main.css";
import { useNavigate } from "react-router-dom";

const BlogSection = () => {
  const navigate = useNavigate();
  return (
    <div className="blog-section">
      <div className="uigrid blog-intro">
        <div className="uigrid-5 blog-header">
          <h3>Our latest articles on our blog.</h3>
        </div>
        <div className="uigrid-5 blog-text">
          <p>
            Tech news and helpful tips for clients and individuals looking to
            build or building products or in the tech industry.
          </p>
        </div>
      </div>
      <div className="uigrid blog-items">
        <BlogItem
          image={vhJimi}
          date="Mar 25, 2023"
          title="Vault Hill raises $2.1m to build its human-centric metaverse leveraging urban planning principles"
          onClick={() => navigate('/blog/vaultHill')}
       />
        <BlogItem
          image={banner}
          date="Mar 27, 2023"
          title="Building Don Jazzy's Burger App and how we delivered the best product possible"
          onClick={() => navigate('/blog/jazzyBurger')}
        />
      </div>
    </div>
  );
};

export default BlogSection;
