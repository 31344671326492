import React from "react";
import ForwardIcon from "../../../UI/IconComponents/ForwardIcon";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import hero from "./images/hero.png";
import "./_Main.scss";

const TopSection = () => {
  return (
    <div className="caseStudy__jazzy-top-section">
      <div className="hero-container">
        <img src={hero} alt="/" />
      </div>
      <Wrapper className="jazzy-top-section__lower">
        <div>
          <span>
            <h6>Company Background</h6>
            <p>
              Jazzy’s Burger, founded by renowned Nigerian music producer Don
              Jazzy, specializes in selling delicious burgers and other fast
              food items. To expand their reach and improve their customer
              experience, they partnered with our company to create a mobile app
              that would allow customers to easily place orders and receive
              updates on their food delivery.
            </p>
          </span>
          <span>
            <h6>Goal</h6>
            <p>
              The goals of creating the Jazzy’s Burger app were to expand the
              company's reach, improve the customer experience, and increase
              sales through a user-friendly platform for ordering and delivery.
            </p>
          </span>
          <div style={{ margin: 0, width: "100%", position: "relative" }}>
            <span className="jazzy-top-section__lower-launch-btn">
              <p>Launch Project</p>
              <ForwardIcon size={35} hoverColor={"rgb(0, 186, 128)"} />
            </span>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default TopSection;
