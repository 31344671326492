import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import DesignItem from "./DesignItem";
import header from "./images/header.jpg";
import phone1 from "./images/phone1.jpg";
import phone2 from "./images/phone2.jpg";
import phone3 from "./images/phone3.jpg";
import phone4 from "./images/phone4.jpg";
import phone5 from "./images/phone5.jpg";
import phone6 from "./images/phone6.jpg";
import box1 from "./images/box1.jpg";
import box2 from "./images/box2.png";
import box3 from "./images/box3.png";
import box4 from "./images/box4.png";
import box5 from "./images/box5.png";
import box6 from "./images/box6.png";
import "./_Main.scss";

const DesignSection = () => {
  return (
    <Wrapper>
      <div className="jazzy__design-section">
        <header>
          <img src={header} alt="/" />
        </header>
        <div className="jazzy__design-item-container">
          <DesignItem
            phone={phone1}
            box={box1}
            title="Your Favorite Burger Joint Just Got Better"
            subtitle="We designed a modern and fun app for Jazzy Burger that features a landing page showcasing their best deals and a user-friendly interface for browsing and selecting items from their menu."
          />
          <DesignItem
            phone={phone2}
            box={box2}
            title="Menu Page Browse Delicious Burgers"
            direction="row-reverse"
            subtitle="We designed a page to showcase Jazzy Burger's wide range of burger varieties and other fast food items, offering users a comprehensive view of their menu options."
          />
          <DesignItem
            phone={phone3}
            box={box3}
            title="Customizable Product Screen"
            subtitle="Our team designed a product page for Jazzy Burger's app that allows users to view detailed descriptions of their menu items, customize their burgers by adding or removing ingredients, and add items to their cart with ease."
          />
          <DesignItem
            phone={phone4}
            box={box4}
            title="Effortless Menu Browsing"
            direction="row-reverse"
            subtitle="We developed helpful search filters to enable users to easily find and navigate through the menu."
          />
          <DesignItem
            phone={phone5}
            box={box5}
            title="Save Your Favorites For Easy Reordering"
            subtitle="We designed a favorites section on the app, which allows users to save their favorite burgers for future orders, making the ordering experience more convenient and personalized."
          />
          <DesignItem
            phone={phone6}
            box={box6}
            title="Seamless Checkout Experience"
            direction="row-reverse"
            subtitle="Our team created a seamless checkout process for Jazzy Burger's app, enabling users to effortlessly review and edit their cart before finalizing their order with secure and convenient payment options."
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default DesignSection;
