import React, { useState } from "react";
import BlurLoader from "../LazyLoader/BlurLoader";
import "./ProfileImage.css";

const ProfileImage = ({ image, style }) => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <figure style={style} className="uigrid-6 UI__profile-image">
      {!loaded && <BlurLoader />}
      <img src={image} alt="/" onLoad={StuffLoaded} />
    </figure>
  );
};

export default ProfileImage;
