import techLead from './images/techLead.png'
import marketing from './images/marketing.png'
import legal1 from './images/legal1.png'
import mobile2 from './images/mobile2.png'
import accounting from './images/accounting.png'

export const TeamInfo = [{
    avatar: techLead,
    personsName: "Uchenna A.",
    position: "Technical Lead"
},
{
    avatar: marketing,
    personsName: "Victory C.",
    position: "Marketing Comms"
},
{
    avatar: legal1,
    personsName: "Moses O.",
    position: "Legal"
},
{
    avatar: mobile2,
    personsName: "Frederick A.",
    position: "Mobile"
},
{
    avatar: accounting,
    personsName: "Eva E.",
    position: "Accounting"
},
// {
//     avatar: legal2,
//     personsName: "Sylvia O.",
//     position: "Legal"
// },
// {
//     avatar: mobile1,
//     personsName: "Victor M.",
//     position: "Mobile"
// },
// {
//     avatar: more ,
//     personsName: "+ More",
// } 
]