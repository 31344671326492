import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTopOnMount from "../Components/ScrollToTop";
import Footer from "../UI/Footer/Footer";
import NavBar from "../UI/NavBar/NavBar";

const Index = () => {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Index;
