import React from "react";
import { useParams } from "react-router-dom";
import ScrollToTopOnMount from "../Components/ScrollToTop";
import ResponsiveError from "../ErrorPage/ResponsiveError";
import Footer from "../UI/Footer/Footer";
import NavBar from "../UI/NavBar/NavBar";
import EAD from "./EAD/EAD";
import JazzysBurger from "./JazzysBurger/JazzysBurger";
import Lynk from "./Lynk/Lynk";
import VaultHill from "./VaultHill/VaultHill";
import Yaaaga from "./Yaaaga/Yaaaga";

const CaseStudies = () => {
  const route = useParams();
  const phoneScreenWidth = 1189;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;
  return (
    <div style={{ paddingTop: 40, backgroundColor: "white" }}>
      <ScrollToTopOnMount />
      <NavBar showNotificationBar={false} />
      {isPhoneScreen ? (
        <ResponsiveError />
      ) : (
        <>
          {route["*"] === "yaaaga" && <Yaaaga />}
          {route["*"] === "vaulthill" && <VaultHill />}
          {route["*"] === "ead" && <EAD />}
          {route["*"] === "jazzysburger" && <JazzysBurger />}
          {route["*"] === "lynk" && <Lynk />}
        </>
      )}
      <Footer />
    </div>
  );
};

export default CaseStudies;
