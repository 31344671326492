import React from 'react'
import Wrapper from '../../../UI/Wrapper/Wrapper'
import banner from './images/banner.png'
import "./_Main.scss"

const TimelineSection = () => {
  return (
    <Wrapper bg_Color="#0D0D0D">
        <div className='yaaaga__timeline-section'>
         <h5 className='text-large light-text font-semibold '>
         Scope & Product Timeline
         </h5>
         <img src={banner} alt="/" />
        </div>
    </Wrapper>
  )
}

export default TimelineSection