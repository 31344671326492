import React, { useState } from "react";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";
import hero from "./images/shape.png";
import "./Main.css";

const TopSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <div className="blog__top-section">
      <div className="uigrid blog__top-section-upper">
        <div className="uigrid-6 blog__top-section-header">
          <header>
            <h5>Awa Blog</h5>
            <p>
              Here we share relevant tech updates and information regarding
              trending topics in the technological ecosystem. Don't miss our
              daily reviews and content.
            </p>
          </header>
        </div>
      </div>
      <div className="blog__top-section-lower">
        <div className="uigrid">
          <figure className="uigrid-12 blog__top-section-img-container">
            {!loaded && <BlurLoader />}
            <img src={hero} alt="/" onLoad={StuffLoaded} />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
