import React from "react";
import PropTypes from "prop-types";
const NavigationIcon = ({ onClick, size = 20 }) => {
  return (
    <svg
      style={{ width: size, height: size }}
      onClick={onClick}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.47 20C18.403 20 19.97 18.433 19.97 16.5C19.97 14.567 18.403 13 16.47 13C14.537 13 12.97 14.567 12.97 16.5C12.97 18.433 14.537 20 16.47 20Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M27.97 26H30.97C32.07 26 32.97 26.9 32.97 28V31C32.97 32.1 32.07 33 30.97 33H27.97C26.87 33 25.97 32.1 25.97 31V28C25.97 26.9 26.87 26 27.97 26Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M22.9999 16H25.6799C27.5299 16 28.3899 18.29 26.9999 19.51L19.0099 26.5C17.6199 27.71 18.4799 30 20.3199 30H22.9999"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4862 16.5H16.4978"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.4862 29.5H29.4978"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="1" width="44" height="44" rx="6" stroke="white" />
    </svg>
  );
};
NavigationIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};
export default NavigationIcon;
