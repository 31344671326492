import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import "./_Main.scss";

const Typography = () => {
  return (
    <Wrapper>
      <div className="jazzy__typography">
        <header>
          <p className="text-medium-sm font-semibold dark-text">
            Typography & Color
          </p>
        </header>
        <div className="flex-container">
          <div>
            <h1>Inter</h1>
            <span>
              <p className="text-medium-sm font-normal void-text">
                Inter Regular
              </p>
              <p className="text-medium-sm font-medium void-text">
                Inter Medium
              </p>
              <p className="text-medium-sm font-semibold void-text">
                Inter Semibold
              </p>
              <p className="text-medium-sm font-bold void-text">Inter Bold</p>
            </span>
          </div>
          <div>
            <span>
              <p className="text-medium-sm font-medium light-text">Black</p>
              <p className="text-medium-sm font-normal light-text opacity">
                #000000
              </p>
            </span>
            <span>
              <p className="text-medium-sm font-medium void-text">Light Gray</p>
              <p className="text-medium-sm font-normal void-text opacity">
                #F0F0F0
              </p>
            </span>
            <span>
              <p className="text-medium-sm font-medium light-text">
                Coral Pink
              </p>
              <p className="text-medium-sm font-normal light-text opacity">
                #FF9182
              </p>
            </span>
            <span>
              <p className="text-medium-sm font-medium light-text">Dark Red</p>
              <p className="text-medium-sm font-normal light-text opacity">
                #FF2709
              </p>
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Typography;
