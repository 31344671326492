import React from 'react';
import PropTypes from 'prop-types';
const Logo = ({   onClick, size = 20 }) => {
return(
<svg
onClick={onClick}
width={size} height={size} viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M34.3927 90.5283L0.432199 56.5678L56.9998 0.00015772L113.567 56.5678L79.5978 90.5375L56.9907 67.9303L34.3927 90.5283ZM56.9995 13.3531L13.7848 56.5678L34.3926 77.1756L56.9906 54.5776L79.598 77.185L100.215 56.5683L56.9995 13.3531ZM69.3423 100.795L57.0003 113.137L44.6583 100.795L57.0003 88.453L69.3423 100.795Z" fill="#00BA80"/>
</svg>)};
Logo.propTypes = {
onClick: PropTypes.func,
size: PropTypes.number
};export default Logo;