import React from 'react'
import { useState } from 'react';
import BlurLoader from '../../../UI/LazyLoader/BlurLoader';
import Wrapper from '../../../UI/Wrapper/Wrapper'
import frame1 from './images/Frame1.png'
import frame2 from './images/Frame2.png'
import "./_Main.scss"

const ColorSection = () => {
    const [loaded, setloaded] = useState(false);

    const StuffLoaded = () => {
      setTimeout(() => {
        setloaded(true);
      }, 6000);
    };
  return (
   <Wrapper>
    <div className='yaaaga__color-section'>
     <h5 className='text-large dark-text font-semibold'>Font & Color</h5>
     <div>
     <figure>
        {!loaded && <BlurLoader /> }
        <img src={frame1} alt="/" onLoad={StuffLoaded} />
     </figure>
     <figure>
        {!loaded && <BlurLoader /> }
        <img src={frame2} alt="/" onLoad={StuffLoaded} />
     </figure>
     </div>
    </div>
   </Wrapper>
  )
}

export default ColorSection