import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import gridbox1 from "./images/gridbox1.png";
import gridbox2 from "./images/gridbox2.png";
import gridbox3 from "./images/gridbox3.png";
import gridbox4 from "./images/gridbox4.png";
import banner from "./images/banner.png";
import "./_Main.scss";

const GridSection = () => {
  return (
    <div className="valthill__grid-section">
      <Wrapper>
        <div className="valthill__grid-section-inner">
          <div>
            <span>
              <img src={gridbox1} alt="/" />
              <p className="text-small extradark-text font-normal">
                We used 3D illustrations to enhance Vault Hill's metaverse
                website, creating a visually captivating and immersive platform.
              </p>
            </span>
            <span>
              <img src={gridbox2} alt="/" />
              <p className="text-small extradark-text font-normal">
                We used Vault Hill's brand colors to design a website that
                represents their brand identity and showcases their metaverse
                technology.
              </p>
            </span>
            <span>
              <img src={gridbox3} alt="/" />
            </span>
            <span>
              <img src={gridbox4} alt="/" />
            </span>
          </div>
        </div>
      </Wrapper>
      <div className="banner">
        <img src={banner} alt="/" />
      </div>
    </div>
  );
};

export default GridSection;
