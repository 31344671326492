import React from "react";
import ScrollToTopOnMount from "../Components/ScrollToTop";
import Footer from "../UI/Footer/Footer";
import Navbar from "../UI/NavBar/NavBar";
import ClientSection from "./ClientSection/Main";
import ImpactSection from "./ImpactSection/Main";
import StorySection from "./StorySection/Main";
import TeamSection from "./TeamSection/Main";
import TopSection from "./TopSection/Main";
import ResponsiveError from "./../../src/ErrorPage/ResponsiveError";
const AboutUs = () => {
  const phoneScreenWidth = 1189;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      {isPhoneScreen ? (
        <ResponsiveError />
      ) : (
        <>
          <TopSection />
          <StorySection />
          <TeamSection />
          <ImpactSection />
          <ClientSection />
        </>
      )}
      <Footer />
    </div>
  );
};

export default AboutUs;
