import React from "react";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faDribbble,
} from "@fortawesome/free-brands-svg-icons";
import SocialLink from "./SocialLink";
import "./MainFooter.css";

import footerLogo from "./images/logo-footer.svg";

import "./Mainfoooter.css";
import { Link, useLocation } from "react-router-dom";

const MainFooter = () => {
  const location = useLocation();

  return (
    <div className="main-footer">
      <div className=" uigrid footer-top">
        <div className="footer-logo uigrid-2">
          <img src={footerLogo} alt="" />
        </div>
        <div className="footer-sm-links uigrid-2">
          <div className="sm-links-inner">
            <SocialLink
              icon={faFacebook}
              link="https://www.facebook.com/awadigital"
            />
            <SocialLink
              icon={faInstagram}
              link="https://www.instagram.com/awa.digital"
            />
            <SocialLink
              icon={faTwitter}
              link="https://twitter.com/awadigitalco"
            />
            <SocialLink
              icon={faDribbble}
              link="https://dribbble.com/awadigital"
            />
          </div>
        </div>
      </div>
      <div className="uigrid footer-bottom">
        <div className="uigrid-9 footer-links">
          <ul>
            <li>
              <Link
                to="/aboutUs"
                className={
                  location.pathname.substring(1) === "aboutUs"
                    ? "nav-active hoverable"
                    : "hoverable"
                }
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className={
                  location.pathname.substring(1) === "services"
                    ? "nav-active hoverable"
                    : "hoverable"
                }
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/pricing"
                className={
                  location.pathname.substring(1) === "pricing"
                    ? "nav-active hoverable"
                    : "hoverable"
                }
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                className={
                  location.pathname.substring(1) === "blog"
                    ? "nav-active hoverable"
                    : "hoverable"
                }
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contactUs"
                className={
                  location.pathname.substring(1) === "contactUs"
                    ? "nav-active hoverable"
                    : "hoverable"
                }
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="uigrid-3 footer-copyright">
          <p>Copyright © Awa Digital LLC, 2022</p>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
