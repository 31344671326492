import React, { useState } from "react";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import flexbox1 from "./images/flexbox1.jpg";
import flexbox2 from "./images/flexbox2.jpg";
import flexbox3 from "./images/flexbox3.jpg";
import flexbox4 from "./images/flexbox4.jpg";
import "./_Main.scss";

const LogoSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <Wrapper>
      <div className="jazzy__logo-section">
        <header>
          <p className="text-medium-sm font-semibold dark-text">
            Logo Redesign & App Icon
          </p>
        </header>
        <div className="jazzy-flex">
          <figure>
            {!loaded && <BlurLoader />}
            <img src={flexbox1} alt="/" onLoad={StuffLoaded} />
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={flexbox2} alt="/" onLoad={StuffLoaded} />
          </figure>
        </div>
        <div className="jazzy-flex2">
          <figure>
            {!loaded && <BlurLoader />}
            <img src={flexbox3} alt="/" onLoad={StuffLoaded} />
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={flexbox4} alt="/" onLoad={StuffLoaded} />
          </figure>
        </div>
      </div>
    </Wrapper>
  );
};

export default LogoSection;
