import React from "react";
import { ProfileInfo } from "../../UI/ProfileImage/ProfileInfo";
import ProfileImage from "../../UI/ProfileImage/ProfileImage";
import ProfileText from "../../UI/ProfileText/ProfileText";
import image from "./images/workspace.png";
import "./Main.css";
const ClientSection = () => {
  return (
    <div className="services__client-section">
      <div className="services__client-section-inner">
        <div className="uigrid services__client-section-main">
          <ProfileImage image={image} key={Math.random().toString()} />

          <ProfileText
            style={{
              gridColumnEnd: "-1",
            }}
            title="Bridging the Digital Divide"
            description="My motivation for establishing Awa Digital stemmed from a deep-seated passion to close the gap in digital accessibility. I aimed to provide businesses, regardless of their size, with access to affordable and advanced technology solutions. This drive was fueled by the desire to empower these businesses, enabling them to embrace innovation and achieve growth in the increasingly digital world."
            personsName="Uchenna Mba"
            position="Founder"
          />
        </div>
        {/* <div className="uigrid services__client-section-main">
        <ProfileText
          title="Help us reach our milestone on time"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          personsName="Parta Mas Hadi"
          position="Senior Vice President of Sales"
        />
        {ProfileInfo.map(({ image }) => (
          <ProfileImage
            image={image}
            key={Math.random().toString()}
            style={{
              gridColumnEnd: "-1",
            }}
          />
        ))}
      </div> */}
      </div>
    </div>
  );
};

export default ClientSection;
