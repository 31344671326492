import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadHeader from "../EadHeader";
import banner from "./images/banner.png";
import "./_Main.scss";

const Screens = () => {
  return (
    <Wrapper className="ead__screens">
      <EadHeader title="other screens" num="10" />
      <h5 className="text-large font-semibold light-text">
        We designed over 170 screens and counting
      </h5>
      <img src={banner} alt="/" />
    </Wrapper>
  );
};

export default Screens;
