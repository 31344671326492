import React from "react";
import ContactForm from "./ContactForm";
import "./Main.css";

const FormSection = () => {
  return (
    <div className="contact__form-section">
      <div>
        <ContactForm />
      </div>
    </div>
  );
};

export default FormSection;
