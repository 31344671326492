import React from "react";
import Logo from "../../../UI/IconComponents/Logo";
import "./_Main.scss"

const ThanksSection = () => {
  return (
    <div className="yaaaga__thanks-section">
    <div className="yaaaga__thanks-section-inner">
      <div className="yaaaga__thanks-section-top">
        <Logo size={80} />
        <span>
          <p>Thanks for watching!</p>
        </span>
      </div>
      <div className="yaaaga__thanks-section-lower">
        <span>
          <p>Let’s talk about your project</p>
          <p className="text-small light-text font-medium ">
            Let's discuss your project and bring your ideas to life. Share your
            project details and let's get started!
          </p>
        </span>
        <span>
            <input type={"text"} placeholder="Your name"   />
            <input type={"email"} placeholder="Your email"   />
            <textarea placeholder="Describe your project details here..."   />
            <button>Send Message</button>
        </span>
      </div>
    </div>
    <span className="yaaaga__date">2023</span>
    </div>
  );
};

export default ThanksSection;
