import React from "react";
import DesignIcon from "../../../UI/IconComponents/DesignIcon";
import ForwardIcon from "../../../UI/IconComponents/ForwardIcon";
import InterfaceIcon from "../../../UI/IconComponents/InterfaceIcon";
import NavigationIcon from "../../../UI/IconComponents/NavigationIcon";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadTopCard from "./Card/Main";
import banner from "./images/banner.png";
import "./_Main.scss";

const TopSection = () => {
  return (
    <div className="ead__top-section">
      <img src={banner} alt="/" />{" "}
      <Wrapper bg_Color="#000000">
        <div className="ead__top-section-upper">
          <span>
            <h6 className="text-medium-semi font-semibold light-text">About</h6>
            <p className="text-small font-normal light-text">
              Eve After Dark is a premium cloud kitchen offering an extensive
              menu of homemade meals and round-the-clock delivery service,
              perfect for busy individuals or those looking for a luxurious
              dining experience from the comfort of their own home.
            </p>
          </span>
          <span>
            <h6 className="text-medium-semi font-semibold light-text">Goal</h6>
            <p className="text-small font-normal light-text">
              We were thrilled to partner with a food delivery company to create
              an intuitive and user-friendly food ordering website, with the
              goal of simplifying the online ordering process for hungry
              customers.
            </p>
          </span>
          <div className="ead__top-section-btn">
            <button className="text-medium font-normal light-text">
              Launch Project{" "}
              <ForwardIcon
                size={30}
                fill="#DDDDDD"
                hoverColor="rgb(255, 0, 24)"
              />{" "}
            </button>
          </div>
        </div>
      </Wrapper>
      <div className="ead__top-section-lower">
        <span>
          <p className="text-small font-bold light-text">Problems</p>
          <p className="text-small font-bold light-text">01</p>
        </span>
        <div className="top-section-lower-float">
          <EadTopCard
            SvgIcon={<NavigationIcon size={44} />}
            title="Poor navigation"
            description="Poor navigation on food ordering websites frustrates users and causes them to abandon their search, and discouraging users from returning."
            bg_color="transparent"
          />
          <EadTopCard
            SvgIcon={<InterfaceIcon size={44} />}
            title="Confusing Interfaces"
            description="A confusing interface can be overwhelming for users and make it difficult for them to place an order. This can lead to abandoned orders and lost revenue."
            bg_color="#FF0018"
            radius={24}
          />
          <EadTopCard
            SvgIcon={<DesignIcon size={44} />}
            title="inconsistent design"
            description="Inconsistent design elements can make it difficult for users to navigate the website and find what they are looking for. This can lead to confusion and frustration."
            bg_color="transparent"
          />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
