import TopRHS from "./TopRHS";
import TopLHS from "./TopLHS";

import "./Main.css";
const TopSection = () => {
  return (
    <div className="top-section">
      <div className="uigrid">
        <TopLHS />
        <TopRHS />
      </div>
    </div>
  );
};
export default TopSection;
