import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import FontItem from "./FontItem";
import banner from "./images/banner.png";
import "./_Main.scss";

const LogoSection = () => {
  return (
    <div>
      <div className="lynk__logo-section">
        <Wrapper className="banner-container">
          <p className="text-medium font-semibold dark-text">Logo Design</p>
          <figure>
            <img src={banner} alt="/" />
          </figure>
        </Wrapper>
        <Wrapper className="logo-section__lower">
          <p className="text-medium font-semibold dark-text">
            Typography & Color
          </p>
          <div className="font-container">
            <div className="font-box">
              <span>
                <p className="text-small font-medium opacity void-text">01.</p>
                <h5>Tomato Grotesk</h5>
              </span>
              <span>
                <p className="text-medium-semi void-text font-medium">
                  Tomato Grotesk SemiBold
                </p>
                <p className="text-medium-semi void-text font-bold">
                  Tomato Grotesk Bold
                </p>
              </span>
            </div>
            <div className="font-box2">
              <span>
                <p className="text-small font-medium opacity void-text">02.</p>
                <h5>U8</h5>
              </span>
              <span>
                <p className="text-medium-semi void-text font-normal">
                  U8 Regular
                </p>
                <p className="text-medium-semi void-text font-medium">
                  U8 Medium
                </p>
              </span>
            </div>
          </div>
          <div className="ellipse-container">
            <FontItem
              color="Light Green"
              borderColor="rgba(0, 186, 128, 0.3)"
              bg_color="#00BA80"
              hexCode="#00BA80"
            />
            <FontItem
              color="Soft Red"
              borderColor="rgba(238, 82, 83, 0.3)"
              bg_color="#EE5253"
              hexCode="#EE5253"
            />
            <FontItem
              color="Dark Green"
              borderColor="rgba(4, 47, 36, 0.3)"
              bg_color="#042F24"
              hexCode="#042F24"
            />
            <FontItem
              color="Violet"
              borderColor="rgba(95, 39, 205, 0.3)"
              bg_color="#5F27CD"
              hexCode="#5F27CD"
            />
            <FontItem
              color="Cyan"
              borderColor="rgba(0, 210, 211, 0.3)"
              bg_color="#00D2D3"
              hexCode="#00D2D3"
            />
            <FontItem
              color="Lime Green"
              borderColor="rgba(16, 172, 132, 0.3)"
              bg_color="#10AC84"
              hexCode="#10AC84"
            />
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default LogoSection;
