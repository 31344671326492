import React, { useState } from "react";
import PropTypes from "prop-types";
const ForwardIcon = ({ fill, hoverColor, onClick, size = 20 }) => {
  const [filled, setFilled] = useState(fill);
  const handleMouseEnter = () => {
    hoverColor && setFilled(hoverColor);
  };
  const handleMouseLeave = (e) => {
    setFilled(fill);
  };
  return (
    <svg
      style={{
        transition: "stroke 0.4s",
        width: size,
        height: size,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        style={{
          transition: "stroke 0.4s",
          stroke: filled,
          pointerEvents: "none",
        }}
        onMouseEnter={(e) => {
          if (hoverColor) setFilled(hoverColor);
        }}
        onMouseLeave={(e) => {
          setFilled(fill);
        }}
        cx="19"
        cy="19"
        r="18"
        stroke="#292929"
        strokeWidth="2"
      />
      <path
        style={{ transition: "fill 0.4s", fill: filled, pointerEvents: "none" }}
        onMouseEnter={(e) => {
          if (hoverColor) setFilled(hoverColor);
        }}
        onMouseLeave={(e) => {
          setFilled(fill);
        }}
        d="M24.414 17.586L20.121 13.293L18.707 14.707L22 18H12V20H22L18.707 23.293L20.121 24.707L24.414 20.414C24.7889 20.0389 24.9996 19.5303 24.9996 19C24.9996 18.4696 24.7889 17.961 24.414 17.586Z"
        fill="#292929"
      />
    </svg>
  );
};
ForwardIcon.propTypes = {
  fill: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
};
export default ForwardIcon;
