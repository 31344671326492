import React from 'react'
import './ProfileText.css'

const ProfileText = ({title, description, personsName, position, style}) => {
  return (
    <div style={style} className='uigrid-5 UI__profile-text-container' >
        <header className='UI__profile-text-header'>
            <h5>{title}</h5>
        </header>
        <div className='UI__profile-text-description'>
            <p>{description}</p>
        </div>
        <div className='UI__profile-text-info'>
            <h5>{personsName}</h5>
            <p>{position}</p>
        </div>
    </div>
  )
}

export default ProfileText