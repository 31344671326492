import React, { useState } from "react";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadHeader from "../EadHeader";
import banner from "./images/banner.png";
import "./_Main.scss";

const TimelineSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <Wrapper>
      <div className="ead__timeline-section">
        <EadHeader title="Timeline" num="03" />
        <div className="timeline-section-dates">
          <span className="text-medium-semi font-semibold">December</span>
          <span className="text-medium-semi font-semibold">January</span>
          <span className="text-medium-semi font-semibold">February</span>
        </div>
        <figure>
          {!loaded && <BlurLoader />}
          <img src={banner} alt="/" onLoad={StuffLoaded} />
        </figure>
      </div>
    </Wrapper>
  );
};

export default TimelineSection;
