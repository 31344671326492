import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadHeader from "../EadHeader";
import EadTitle from "../EadTitle";
import banner from "./images/banner.png";
import phone1 from "./images/phone1.png";
import phone2 from "./images/phone2.png";
import "./_Main.scss";

const Catalogue = () => {
  return (
    <Wrapper>
      <div className="ead__catalogue">
        <EadHeader title="catalogue" num="06" />
        <EadTitle
          title="Simplifying Food Selection with a User-Friendly Filter"
          description="To help customers easily find the food they are looking for in EAD's catalogue, a useful filter was implemented, providing an intuitive and efficient way to sort and search through the wide range of menu options."
        />
        <div className="catalogue__img-container">
          <img src={banner} alt="/" />
          <img src={phone1} alt="/" />
          <div className="img-container__lower">
            <img src={phone2} alt="/" />
            <span>
              <p className="text-medium font-medium light-text">
                Enhancing food selection
              </p>
              <p>
                To assist customers in selecting from EAD's extensive menu, a
                user-friendly filter was implemented with visually appealing
                pictures of popular meals, making it easy and enjoyable for
                customers who may be unsure of what to order.
              </p>
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Catalogue;
