import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadHeader from "../EadHeader";
import EadTitle from "../EadTitle";
import banner1 from "./images/banner1.png";
import banner2 from "./images/banner2.png";
import banner3 from "./images/banner3.png";
import phone1 from "./images/phone1.png";
import phone2 from "./images/phone2.png";
import "./_Main.scss";
const CartandTracking = () => {
  return (
    <div>
      <div className="ead__Cart-and-tracking">
        <Wrapper className="container">
          <EadHeader title="Cart" num="07" />
          <EadTitle
            title="Streamlining the Checkout Process with a User-Friendly Shopping Cart"
            description="To make the checkout process easier and more streamlined, a shopping cart section was designed, allowing customers to review and confirm the content of their cart before proceeding with the checkout process."
          />
          <figure>
            <img src={banner1} alt="/" />
            <img src={phone1} alt="/" />
          </figure>
        </Wrapper>
        <Wrapper className="container">
          <EadHeader title="Cart" num="08" />
          <EadTitle
            title="Simplified Ordering Experience"
            description="A step-by-step checkout process was designed to simplify and enhance the ordering experience for customers, with clear indications of progress throughout the process."
          />
          <figure>
            <img src={banner2} alt="/" />
            <img src={phone2} alt="/" />
          </figure>
        </Wrapper>
        <Wrapper className="container">
          <EadHeader title="Tracking" num="09" />
          <EadTitle
            title="Tracking Made Easy"
            description="To help customers track their orders more easily, a tracking feature was designed and implemented, which displays a map, receiver details, rider's location, and rider's information all in one place."
          />
          <img style={{ width: "100%" }} src={banner3} alt="/" />
        </Wrapper>
      </div>
    </div>
  );
};

export default CartandTracking;
