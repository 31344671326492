import React from "react";
import "./BlurLoader.css";

const BlurLoader = ({ src, style, className }) => {
  return (
    <div className="blur-loader-container">
      <div className={`blur-loader ${className}`} style={style}></div>
    </div>
  );
};

export default BlurLoader;
