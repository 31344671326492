import React from "react";
import "./ImpactInfo.css";

const ImpactInfo = () => {
  return (
    <div className="uigrid-6 about__impact-info-container">
      <div className="about__impact-info-header">
        <h1>How passionate</h1>
        <h1>are we?</h1>
      </div>
      <div className="about__impact-info-description">
        We are extremely passionate about helping our clients actualize their vision, not only efficiently, but on time too. We work round the clock to stay on schedule and avoid delays. Our commitment to being the best industry is surpassed only by our drive to foster customer satisfaction.
      </div>
    </div>
  );
};

export default ImpactInfo;
