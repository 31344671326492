import React from 'react'
import banner from './images/banner.png'
import sphere from './images/sphere.png'
import bitmap from './images/Bitmap.png'
import './_Main.scss'

const TopSection = () => {
  return (
    <div className='vaulthill__top-section'>
    <img src={banner} alt="/" />
    <img src={sphere} alt="/" />
    <img src={bitmap} alt="/" />
    </div>
  )
}

export default TopSection