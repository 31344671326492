import ServiceItem from "./ServiceItem";
import "./Main.css";

const HowSection = () => {
  return (
    <div className="how-section">
      <div className="uigrid how-intro">
        <div className="uigrid-6 how-header">
          <h3>We work as an extension of your team.</h3>
        </div>
        <div className="uigrid-5 how-text">
          <p>
            Software products shouldn’t take forever to design, build and
            deploy. Focus on the core of your business and let our dedicated
            team build applications faster for you.
          </p>
        </div>
      </div>
      <div className="uigrid service-items">
        <ServiceItem
          title="We do research"
          description="We gather problems from your product to find out ways to make it better"
          url="#0"
          id="research"
        />
        <ServiceItem
          title="Do a discussion"
          description="Converstions with client and team help us attain required results"
          url="#0"
          id="discussion"
        />
        <ServiceItem
          title="Ideation with team"
          description="Thorough creative sessions with our team to create value adding solutions"
          url="#0"
          id="ideation"
        />
        <ServiceItem
          title="Implementation"
          description="We implement wireframes and prototypes one milestone at a time"
          url="#0"
          id="implementation"
        />
      </div>
    </div>
  );
};

export default HowSection;
