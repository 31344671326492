import React from "react";
import './TeamCard.css'

const TeamCard = ({avatar, personsName, position}) => {
  return (
    <div className="about__team-card">
      <figure className="about__team-card-img">
        <img src={avatar} alt="/" />
      </figure>
      <div className="about__team-card-subtitle">
        <h5>{personsName}</h5>
        <p>{position}</p>
      </div>
    </div>
  );
};

export default TeamCard;
