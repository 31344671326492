import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import macbook from './images/macbook.png';
import phone1 from './images/phone1.png';
import phone2 from './images/phone2.png';
import './_Main.scss';

const JourneySection = () => {
  return (
    <Wrapper className="vaulthill__journey-section">
      <div className="vaulthill__journey-section-inner">
        <header>
          <span>
            <h5 className="extradark-text font-semibold">
              Journey to the Metaverse: A Vibrant Website for Vault Hill's
              Blockchain-based Platform.
            </h5>
          </span>
          <span>
            <p className="text-medium font-semibold extradark-text">
              Landing Page
            </p>
            <p className="text-small extradark-text font-medium">
              Vault Hill's groundbreaking blockchain-based metaverse inspired us
              to design a dynamic and immersive website that takes visitors on a
              journey into a futuristic realm. Through vivid colors and extended
              reality experiences that tap into our primal instincts, we're
              excited to showcase the endless possibilities of this innovative
              technology.
            </p>
          </span>
        </header>
        <div>
            <img src={macbook} alt='/' />
            <img src={phone1} alt='/' />
            <img src={phone2} alt='/' />
        </div>
      </div>
    </Wrapper>
  );
};

export default JourneySection;
