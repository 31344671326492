import NavBar from "../UI/NavBar/NavBar";
import TopSection from "./TopSection/Main";
import HowSection from "./HowSection/Main";
import ProjectSection from "./ProjectsSection/Main";
import PassionSection from "./PassionSection/Main";
import BlogSection from "./BlogSection/Main";
import Footer from "../UI/Footer/Footer";
import "../UI/Grid.css";
const Index = () => {
  return (
    <div>
      <NavBar />
      <TopSection />
      <HowSection />
      <ProjectSection />
      <PassionSection />
      <BlogSection />
      <Footer />
    </div>
  );
};

export default Index;
