import React from "react";
import screens from "./images/screens.png";
import "./_Main.scss";
const Screens = () => {
  return (
    <div className="yaaaga_banner" style={{ width: "100vw" }}>
      <img style={{ width: "100%" }} src={screens} alt="/" />
    </div>
  );
};

export default Screens;
