import React, { useState, useEffect } from "react";
import Triangle from "./images/triangle.svg";
import Hexagon from "./images/hexagon.svg";
import Scratch from "./images/scratch.svg";
import Slider from "./Slider";
import "./TopRHS.css";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";

const TopRHS = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 3000);
  };
  useEffect(() => {
    StuffLoaded();
  }, []);

  return (
    <div className="uigrid-5 top-rhs">
      <div className="rhs-inner">
        <div className="image-slide-container">
          <div className="image-holder">
            {!loaded && <BlurLoader />}
            <Slider />
          </div>
          <div className="triangle">
            <img src={Triangle} alt="" />
          </div>
          <div className="scratch">
            <img src={Scratch} alt="" />
          </div>
          <div className="hexagon">
            <img src={Hexagon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRHS;
