import React from "react";

const Wrapper = ({bg_Color, className, children}) => {
  return (
    <div
      style={{padding:"100px 0", backgroundColor: bg_Color }}
      className={className}
    >
      {children}
    </div>
  );
};

export default Wrapper;