import React from "react";
import banner from "./images/banner.jpg";
import "./_Main.scss";

const Screens = () => {
  return (
    <div className="jazzy__screens">
      <img src={banner} alt="/" />
    </div>
  );
};

export default Screens;
