import Button from "../../UI/Button/Button";
import TextSketch from "./images/textsketch.svg";
import "./TopLHS.css";

const TopLHS = () => {
  return (
    <div className="uigrid-6 top-lhs">
      <div className="lhs-inner">
        <div className="header-text">
          <h1>Business value through digital</h1>
          <h1>products</h1>
          <div className="header-text-sketch">
            <img src={TextSketch} alt="" />
          </div>
        </div>
        <div className="header-description">
          <p>
            <span>awa</span>some products should not be out of reach for small
            starters, so our services are less than half the price of setting up
            an in house team.
          </p>
        </div>

        <div className="header-links">
          <Button value="Book an appointment" class="header-link-btn" />
          <p className="header-links-more">
            I need more details
            <a href="http://" className="header-see-more hoverable">
              See how it works
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopLHS;
