import React from "react";
import PropTypes from "prop-types";
const DesignIcon = ({ onClick, size = 20 }) => {
  return (
    <svg
      style={{ width: size, height: size }}
      onClick={onClick}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.43 26.0004H15.4C13.58 26.0004 12.42 24.0505 13.3 22.4505L15.63 18.2104L17.81 14.2404C18.72 12.5904 21.1 12.5904 22.01 14.2404L24.2 18.2104L25.25 20.1204L26.53 22.4505C27.41 24.0505 26.25 26.0004 24.43 26.0004Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 26.5C33 30.09 30.09 33 26.5 33C22.91 33 20 30.09 20 26.5C20 26.33 20.01 26.17 20.02 26H24.43C26.25 26 27.41 24.05 26.53 22.45L25.25 20.12C25.65 20.04 26.07 20 26.5 20C30.09 20 33 22.91 33 26.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1" y="1" width="44" height="44" rx="6" stroke="white" />
    </svg>
  );
};
DesignIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};
export default DesignIcon;
