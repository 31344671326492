import React from "react";
import facebook from "./images/facebook.png";
import twitter from "./images/twitter.png";
import instagram from "./images/instagram.png";
import dribble from "./images/dribbble.png";
import "./Main.css";
const TopSection = () => {
  return (
    <div className="contact__top-section">
      <div className="uigrid contact__top-section-top ">
        <div className="uigrid-8 contact__top-section-header">
          <header>
            <h3>We would love to hear from you</h3>
            <p>
              Your time is very important to us, which is why our response and
              turnaround time is one of the fastest in the world
            </p>
          </header>
        </div>
      </div>
      <div className="uigrid contact-top-section-lower">
        <div className="uigrid-4 contact__top-section-logos">
          <a href="#0">
            <img src={facebook} alt="/" />
          </a>
          <a href="#0">
            <img src={instagram} alt="/" />
          </a>
          <a href="#0">
            <img src={twitter} alt="/" />
          </a>
          <a href="#0">
            <img src={dribble} alt="/" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
