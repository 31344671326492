import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadHeader from "../EadHeader";
import EadTitle from "../EadTitle";
import banner from "./images/banner.png";
import phone1 from "./images/phone1.png";
import phone2 from "./images/phone2.png";
import "./_Main.scss";

const LandingScreen = () => {
  return (
    <Wrapper>
      <div className="ead__landing-screen">
        <EadHeader title="landing screen" num="06" />
        <EadTitle
          title="Simple & Spicy Design"
          description="The EAD food ordering website was given a new look with a simple and spicy landing page design that provides an easy and intuitive user experience for customers looking to get started with their orders."
        />
        <figure>
          <img src={banner} alt="/" />
          <img src={phone1} alt="/" />
          <img src={phone2} alt="/" />
        </figure>
      </div>
    </Wrapper>
  );
};

export default LandingScreen;
