import React from "react";
import ForwardIcon from "../../../UI/IconComponents/ForwardIcon";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import hero from "./images/hero.png";
import "./_Main.scss";

const TopSection = () => {
  return (
    <div className="caseStudy__lynk-top-section">
      <div className="hero-container">
        <img src={hero} alt="/" />
      </div>
      <Wrapper className="lynk-top-section__lower">
        <div>
          <span>
            <h6>Company Background</h6>
            <p>
              Lynk is an online platform that simplifies sharing personal or
              professional information through a customizable link. With Lynk,
              users can easily manage their online presence and enhance their
              visibility. It's the perfect tool for influencers, small business
              owners, and anyone who wants to showcase their work or hobbies.
            </p>
          </span>
          <span>
            <h6>Goal</h6>
            <p>
              The goal of creating a website is to help them achieve their
              objective of increasing their online visibility and improving
              their overall online presence. They needed a user-friendly and
              efficient platform that would allow them to consolidate their
              online content into one easy-to-access location.
            </p>
          </span>
          <div style={{ margin: 0, width: "100%", position: "relative" }}>
            <span className="lynk-top-section__lower-launch-btn">
              <p>Launch Project</p>
              <ForwardIcon size={35} hoverColor={"rgb(0, 186, 128)"} />
            </span>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default TopSection;
