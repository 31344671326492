import React, { useState } from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import gridbox1 from "./images/gridbox1.png";
import overlay from "./images/overlay.png";
import gridbox3 from "./images/gridbox3.png";
import gridbox4 from "./images/gridbox4.png";
import gridbox5 from "./images/gridbox5.png";
import overlay2 from "./images/overlay2.png";
import "./_Main.scss";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";

const SolutionSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <Wrapper>
      <div className="ead__solution-section">
        <div>
          <p className="text-small font-bold light-text">Solution</p>
          <p className="text-small font-bold light-text">02</p>
        </div>
        <div>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox1} alt="/" onLoad={StuffLoaded} />{" "}
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={overlay} alt="/" onLoad={StuffLoaded} />{" "}
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox3} alt="/" onLoad={StuffLoaded} />{" "}
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox4} alt="/" onLoad={StuffLoaded} />{" "}
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={gridbox5} alt="/" onLoad={StuffLoaded} />{" "}
          </figure>
          <figure>
            {!loaded && <BlurLoader />}
            <img src={overlay2} alt="/" onLoad={StuffLoaded} />{" "}
          </figure>
        </div>
      </div>
    </Wrapper>
  );
};

export default SolutionSection;
