import React, { useState } from "react";
import PassionImage from "./images/passion-text-image.png";
import PassionInfo from "./PassionInfo";
import "./Main.css";
import BlurLoader from "../../UI/LazyLoader/BlurLoader";

const PassionSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 5000);
  };
  return (
    <div className="passion-section">
      <div className="uigrid">
        <div className="uigrid-6 passion-image-container">
          {!loaded && <BlurLoader />}
          <img src={PassionImage} alt="" onLoad={StuffLoaded} />
        </div>

        <PassionInfo />
      </div>
    </div>
  );
};

export default PassionSection;
