import React from "react";
import "./Main.css";

const ArticleSection = () => {
  return (
    <div className="blogPage__article-section">
      <div className="blogPage__article-section-inner">
        <div className="blogPage__article-section-main">
          <p>
            As a digital agency that specializes in web development and design,
            AwaDigital is proud to have helped Vault Hill secure $2.1m in
            funding for their ambitious project to build a human-centric
            metaverse. We were thrilled to work with such a groundbreaking
            company, and we’re excited to share our story.
          </p>
          <p>
            Our journey with Vault Hill began when they approached us with the
            task of designing and developing their first website. They had a
            clear vision of what they wanted to achieve, but they needed a team
            of experts to help bring their ideas to life. We were eager to take
            on the challenge, and we worked closely with Vault Hill to
            understand their brand, mission, and goals.
          </p>
          <p>
            One of the biggest challenges we faced was communicating the
            complexities of the metaverse in a way that was easy for visitors to
            understand. We wanted to make sure that the website conveyed the
            full scope and potential of the project without overwhelming
            visitors with technical jargon. To achieve this, we created a design
            that was both visually engaging and informative, with clear
            calls-to-action that encouraged visitors to learn more.
          </p>
          <p>
            Another key aspect of our work with Vault Hill was optimizing the
            website for search engines.
          </p>
          <p>
            We knew that SEO would be critical in attracting investors and
            generating buzz around the project. Our team conducted extensive
            keyword research and optimized every page of the website for maximum
            visibility. We also implemented a content strategy that focused on
            creating high-quality, shareable content that would help Vault Hill
            establish itself as a thought leader in the industry.
          </p>
          <p>
            Ultimately, our work paid off. The website we created for Vault Hill
            played a crucial role in securing the funding they needed to bring
            their vision to life. We’re proud to have been a part of such an
            exciting project, and we’re thrilled to see what the future holds
            for Vault Hill and the metaverse.
          </p>
          <p>
            In conclusion, working with Vault Hill was an exciting opportunity
            for AwaDigital to showcase our web development and design expertise.
            We're glad that our efforts in creating a website that communicated
            Vault Hill's vision for the metaverse and optimizing it for search
            engines contributed to their success in securing funding. We look
            forward to seeing how Vault Hill will continue to revolutionize the
            world of virtual reality and human-centric urban planning.
          </p>
          <p className="vaulthill-links">
            <b>Twitter: </b>
            <a
              href="https://twitter.com/vault_hill"
              rel="noreferrer"
              target={"_blank"}
            >
              https://twitter.com/vault_hill
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Instagram: </b>
            <a
              href="https://www.instagram.com/vault_hill/"
              rel="noreferrer"
              target={"_blank"}
            >
              https://www.instagram.com/vault_hill/
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Telegram: </b>
            <a href="https://t.me/Vaulthill" rel="noreferrer" target={"_blank"}>
              https://t.me/Vaulthill
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Discord: </b>
            <a
              href="https://discord.com/invite/Pw6r9wHkZZ"
              rel="noreferrer"
              target={"_blank"}
            >
              https://discord.com/invite/Pw6r9wHkZZ
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Whitepaper: </b>
            <a
              href="https://vaulthill.io/Documents/VHWhitePaper.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              https://vaulthill.io/Documents/VHWhitePaper.pdf
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Company: </b>
            Vault Hill
          </p>
          <p className="vaulthill-links">
            <b>Email: </b>
            <a
              href="mailto: Oyin@vaulthill.io"
              rel="noreferrer"
              target={"_blank"}
            >
              Oyin@vaulthill.io
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Website: </b>
            <a href="https://vaulthill.io/" rel="noreferrer" target={"_blank"}>
              https://vaulthill.io/
            </a>
          </p>
          <p className="vaulthill-links">
            <b>Country - </b>
            London, United Kingdom
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleSection;
