import React from "react";
import ForwardIcon from "../../../UI/IconComponents/ForwardIcon";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import hero from "./images/hero.png";
import "./_Main.scss";

const TopSection = () => {
  return (
    <div className="caseStudy__yaaga-top-section">
      <div className="hero-container">
        <img src={hero} alt="/" />
      </div>
      <Wrapper className="yaaga-top-section__lower">
        <div>
          <span>
            <h6>Company Background</h6>
            <p>
              Yaaaga is a multi faced product that provides users the ability to
              Buy, Send, or Receive Food, Rooms, Drinks. Groceries or even
              Tickets from anywhere in the world, apply for jobs and access
              discounts directly from all their favorite outlets and also
              provides merchants with presales. Their goal is to kill theft,
              limit credits and create an easy ordering process for their users.
            </p>
          </span>
          <span>
            <h6>Goal</h6>
            <p>
              Create a user friendly app to help users to place and receive
              orders with ease and also help merchants list their products.
            </p>
          </span>
          <div style={{ margin: 0, width: "100%", position: "relative" }}>
            <span className="yaaga-top-section__lower-launch-btn">
              <p>Launch Project</p>
              <ForwardIcon size={35} hoverColor={"rgb(0, 186, 128)"} />
            </span>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default TopSection;
