import React, { useState } from "react";
import "./Main.css";
import avatar from "../../../Assets/Images/kobi.jpg";
import hero from "../images/vaulthillHero.png";
import BlurLoader from "../../../../UI/LazyLoader/BlurLoader";
import Socialicons from "../../../../UI/SocialIcons/Socialicons";

const TopSection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <div className="blogPage__top-section">
      <div className="blogPage__top-section-inner">
        <div className="blogPage__top-section-top">
          <div className="blogPage__article-header">
            <div className="blogPage__article-header-img">
              {!loaded && <BlurLoader />}
              <img src={avatar} alt="/" onLoad={StuffLoaded} />
            </div>
            <div className="blogPage__article-header-inner">
              <span className="article-header-name-container">
                <h6>Emmanuel Kobi</h6>
               <span><p>Mar 25 2023 . Updated Mar 25 2023 .</p>
                <p> 10 min read</p>
                </span>
              </span>
              <Socialicons />
            </div>
          </div>
          <div className="blogPage-article-title-container">
            <h3>
              How we Helped Vault Hill accelerate into the Global Landscape
            </h3>
            <p>
              Vault Hill, the first ever human-centric, blockchain-based
              metaverse, is excited to announce that it has successfully
              completed its pre-seed and private funding rounds where it raised
              $2.1 million.
            </p>
          </div>
        </div>
      </div>
      <div className="blogPage__hero-container">
        <div className="blogPage__hero-inner">
          {!loaded && <BlurLoader />}
          <img src={hero} alt="/" onLoad={StuffLoaded} />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
