import React from "react";
import "./GetEstimate.css";

const GetEstimate = () => {
  return (
    <div className="get-estimate uigrid">
      <div className="uigrid-4 cta-intro">
        <div className="cta-intro-inner">
          <h3>Get a quick estimate</h3>
          <p>
            Our time is in GMT +1. We usually respond within 3 hours. Unless
            within 9PM - 7AM
          </p>
        </div>
      </div>

      <form action="" className="uigrid-7 estimate-form">
        <div className="estimate-form-grid">
          <div className="estimate-form-grid-2">
            <div className="estimate-form-item-holder">
              <input
                type="text"
                placeholder="Your Name"
                className="estimate-form-input"
              />
              <div className="estimate-form-spacer"></div>
              <input
                type="text"
                placeholder="Your Email"
                className="estimate-form-input"
              />
            </div>
          </div>
          <div className="estimate-form-grid-5">
            <div className="estimate-form-message-holder">
              <textarea
                type="text-area"
                placeholder="Describe your project details here..."
                className="estimate-form-text"
                rows="3"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GetEstimate;
