import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import macbook from "./images/macbook.png";
import phone1 from "./images/phone1.png";
import phone2 from "./images/phone2.png";
import "./_Main.scss";

const DesignSection = () => {
  return (
    <Wrapper className="vaulthill__design-section">
      <div className="vaulthill__design-section-inner">
        <header>
          <span>
            <h5 className="extradark-text font-semibold">
              Designing an Engaging 'About Us' Page for Vault Hill's
              Blockchain-Based Metaverse Website.
            </h5>
          </span>
          <span>
            <p className="text-medium font-semibold extradark-text">About us</p>
            <p className="text-small extradark-text font-medium">
              We also designed a visually stunning 'About Us' page for Vault
              Hill's metaverse website that showcases the company's mission,
              values, and team members, highlighting the company's unique
              culture and talented workforce.
            </p>
          </span>
        </header>
        <div>
          <img src={macbook} alt="/" />
          <img src={phone1} alt="/" />
          <img src={phone2} alt="/" />
        </div>
      </div>
    </Wrapper>
  );
};

export default DesignSection;
