import React from "react";

const EadHeader = ({ title, num }) => {
  return (
    <>
      <span className="ead__header">
        <p className="text-small font-bold light-text">{title}</p>
        <p className="text-small font-bold light-text">{num}</p>
      </span>
    </>
  );
};

export default EadHeader;
