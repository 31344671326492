import React,{useState} from 'react'
import BlurLoader from '../../UI/LazyLoader/BlurLoader'
import hero from './images/TopRHS.jpg'
import './TopRHS.css'

const TopRHS = () => {
  const [loaded, setloaded] = useState(false)
  
  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true)
    }, 6000);
  }
  return (
    <div className="uigrid-5 about__top-rhs">
    <div className="about__rhs-inner">
      <div className="about__image-slide-container">
        <div className="about__image-holder">
        {!loaded && <BlurLoader /> }
          <img src={hero} alt='/' onLoad={StuffLoaded} />
        </div>
      </div>
    </div>
  </div>
  )
}

export default TopRHS