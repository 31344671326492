import React from "react";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import LynkHeader from "../LynkHeader";
import banner1 from "./images/banner1.png";
import banner2 from "./images/banner2.png";
import phone1 from "./images/phone1.png";
import phone2 from "./images/phone2.png";
import "./_Main.scss";

const Onboarding = () => {
  return (
    <div className="lynk__onboarding">
      <Wrapper className="onboarding-top">
        <div className="top-container">
          <LynkHeader
            page="Onboarding"
            title="From Cluttered Forms to One-Click Sign-ups"
            subtitle="We designed a streamlined and efficient sign-up page that enables users to quickly and easily get started with Lynk."
          />
          <figure>
            <img src={banner1} alt="/" />
            <img src={phone1} alt="/" />
          </figure>
        </div>
      </Wrapper>
      <Wrapper className="onboarding-lower">
        <div className="lower-container">
          <LynkHeader
            page="Onboarding"
            title="Everything in one simple lynk."
            subtitle='We designed a unique dashboard that shows users links for a particular influencer they clicked on, along with their various social media profiles, as well as an "about me" section featuring a short introduction video posted by the influencer and a list of their experience.'
          />
          <figure>
            <img src={banner2} alt="/" />
            <img src={phone2} alt="/" />
          </figure>
        </div>
      </Wrapper>
    </div>
  );
};

export default Onboarding;
