import React from "react";
import TeamCard from "./TeamCard";
import { TeamInfo } from "./TeamInfo";
import "./TeamContent.css"

const TeamContent = () => {
  return (
    <div className="uigrid-12">
      <div className="about__team-content-inner">
        {TeamInfo.map(({ avatar, personsName, position }) => (
          <TeamCard
            avatar={avatar}
            personsName={personsName}
            position={position}
            key={Math.random().toString()}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamContent;
