import React, { useState } from "react";
import BlurLoader from "../../../UI/LazyLoader/BlurLoader";
import Wrapper from "../../../UI/Wrapper/Wrapper";
import EadHeader from "../EadHeader";
import banner from "./images/banner.png";
import box1 from "./images/box1.png";
import box2 from "./images/box2.png";
import "./_Main.scss";

const TypographySection = () => {
  const [loaded, setloaded] = useState(false);

  const StuffLoaded = () => {
    setTimeout(() => {
      setloaded(true);
    }, 6000);
  };
  return (
    <div className="ead__typography-section">
      <div className="ead__typography-section-inner">
        <Wrapper className="typography-section_top">
          <EadHeader title="Typography & Color" num="04" />
          <figure>
            {!loaded && <BlurLoader />}
            <img src={banner} alt="/" onLoad={StuffLoaded} />
          </figure>
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Wrapper>
        <Wrapper className="typography-section-lower">
          <EadHeader title="select location" num="05" />
          <div className="ead__title">
            <p className="text-small font-bold light-text">
              Enhancing User Experience with Location-Based Personalization
            </p>
            <p className="text-small font-medium light-text">
              To assist users in finding the most relevant options for their
              location, a country and address input screen was implemented in
              the Eve After Dark website, allowing for a more personalized and
              efficient user experience.
            </p>
          </div>
          <figure>
            <img src={box1} alt="/" />
            <img src={box2} alt="/" />
          </figure>
        </Wrapper>
      </div>
    </div>
  );
};

export default TypographySection;
