import React from 'react'
import ColorSection from './ColorSection/Main'
import CoreSection from './CoreSection/Main'
import LogoSection from './LogoSection/Main'
import Onboarding from './Onboarding/Main'
import Screens from './Screens/Main'
import ThanksSection from './ThanksSection/Main'
import TimelineSection from './TimelineSection/Main'
import TopSection from './TopSection/Main'

const Yaaaga = () => {
  return (
    <>
     <TopSection />
     <LogoSection />
     <CoreSection />
     <TimelineSection />
     <ColorSection />
     <Onboarding />
     <Screens />
     <ThanksSection />
    </>
  )
}

export default Yaaaga