import React from "react";
import "./PassionInfo.css";

const PassionInfo = () => {
  return (
    <div className="uigrid-6 passion-content-container">
      <div className="passion-section-header">
        <h1>How passionate</h1>
        <h1>are we?</h1>
      </div>
      <div className="passion-description">
        Dive into developing your projects in less than 24hrs. We help new and
        existing businesses save money by providing software dev. as a service -
        SDASS, skipping the time and money spent on setting up an in house
        development team
      </div>
      <div className="passion-ratings">
        <div className="passion-rating-item">
          <div className="passion-rating-rating hoverable">
            <h1>98%</h1>
          </div>
          <div className="passion-rating-info">
            <p>
              Happy <br />
              Businesses
            </p>
          </div>
        </div>
        <div className="passion-rating-item">
          <div className="passion-rating-rating hoverable">
            <h1>43</h1>
          </div>
          <div className="passion-rating-info ">
            <p>
              Completed <br />
              Projects
            </p>
          </div>
        </div>
        <div className="passion-rating-clear"></div>
      </div>
    </div>
  );
};

export default PassionInfo;
