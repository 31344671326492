import React from "react";
import storyRect from "./images/story_rect.png";
import "./StoryContent.css";

const StoryContent = () => {
  return (
    <div className="uigrid-10 about__story-content">
      <div className="about__story-content-inner">
        <div className="about__story-content-img">
          <img src={storyRect} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default StoryContent;
