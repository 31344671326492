import React, { useState } from "react";
import SeeMore from "./images/button=default.svg";
import SeeMoreActive from "./images/button=active.svg";

import "./ProjectContent.css";

const ProjectContent = (props) => {
  const [arrowBtn, setArrowBtn] = useState(SeeMore);

  const hoverEventHandler = () => {
    setArrowBtn(SeeMoreActive);
  };
  const hoverOutEventHandler = () => {
    setArrowBtn(SeeMore);
  };
  return (
    <div
      className="uigrid-7 project-content"
      onMouseEnter={hoverEventHandler}
      onMouseLeave={hoverOutEventHandler}
    >
      <div className="project-content-inner">
        <div className="project-title">
          <h1>{props.title}</h1>
        </div>
        <div className="project-description">
          <p>{props.description}</p>
        </div>
        <div
          className="project-see-more indicator-image hoverable"
          style={{ backgroundImage: `url(${arrowBtn})` }}
          onClick={props.onClick}
        ></div>
      </div>
    </div>
  );
};

export default ProjectContent;
