import React from "react";

const EadTopCard = ({ bg_color, radius, SvgIcon, title, description }) => {
  return (
    <div
      className="ead__top-section-lower-card"
      style={{ backgroundColor: bg_color, borderRadius: radius }}
    >
      <>{SvgIcon}</>
      <h6 className="text-small font-bold light-text">{title}</h6>
      <p className="">{description}</p>
    </div>
  );
};

export default EadTopCard;
