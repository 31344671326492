import React from "react";
import GetEstimate from "../CallToAction/GetEstimate";
import MainFooter from "./MainFooter";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <GetEstimate />
      <div className="footer-main"></div>
      <MainFooter />
    </div>
  );
};

export default Footer;
