import React from 'react'
import { useState } from 'react'
import BlurLoader from '../../../UI/LazyLoader/BlurLoader'
import Wrapper from '../../../UI/Wrapper/Wrapper'
import banner from './images/banner.png'
import cube from './images/cube.png'
import './_Main.scss'

const TimelineSection = () => {
    const [loaded, setloaded] = useState(false);

    const StuffLoaded = () => {
      setTimeout(() => {
        setloaded(true);
      }, 6000);
    };
  return (
    <Wrapper className='vaulthill__timeline-section'>
      <figure className='vaulthill__timeline-section-img'>
      {!loaded && <BlurLoader />}
        <img src={banner} alt="/" onLoad={StuffLoaded} />
        </figure>  
        <img className='cube-img' src={cube} alt="/" />
    </Wrapper>
  )
}

export default TimelineSection