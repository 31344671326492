import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialLink = (props) => {
  const linkOpenerHandler = () => {
    window.open(props.link, "_blank");
  };

  return (
    <div className="footer-sm-item hoverable" onClick={linkOpenerHandler}>
      <FontAwesomeIcon icon={props.icon} />
    </div>
  );
};

export default SocialLink;
